import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { LoadCurrentUserAction } from "./user.actions";
import { UsersService } from "../../../services/users.service";
import { User } from "../../../interfaces/user";

@State<User>({
  name: 'UserState',
  defaults: {
      name: '',
      email: '',
      uuid: '',
      permissions: [],
      search_count: 0,
      roles: []
  }
})
@Injectable()
export class UserState {

    constructor(private usersService: UsersService) {}

    @Selector()
    static currenUser(state: User): User {
        return state;
    }

    @Action(LoadCurrentUserAction)
    loadCurrentUser({ setState }: StateContext<User>) {
        this.usersService.currentUser().subscribe(user => {
            setState(user);
        });
    }
}
