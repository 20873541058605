import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {Peps} from "../../interfaces/peps/peps";

@Component({
    selector: 'app-peps-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
    templateUrl: './peps-display.component.html',
    styleUrl: './peps-display.component.scss'
})
export class PepsDisplayComponent {

    @Input() records: Peps[] = [];

}
