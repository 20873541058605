<div *ngIf="causas">
    <p-multiSelect [options]="delitos" [(ngModel)]="selectedDelitos" (onChange)="filter()"></p-multiSelect>

    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="filteredCausas" [rows]="10" [paginator]="true">

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        ID Juicio
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre del Delito
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Fecha de Ingreso
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-causa>
            <tr>
                <td>
                    {{causa.idJuicio}}
                </td>
                <td>

                    {{causa.nombreDelito}}

                </td>
                <td>
                    {{causa.fechaIngreso | date: 'yyyy/MM/dd hh:mm a'}}
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
