<div *ngIf="records.length > 0">

    <p-table responsiveLayout="scroll" [value]="filteredEntities" [rows]="10" [paginator]="true" styleClass="p-datatable-gridlines" >

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Entidad
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Jurisdicción
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Enlazado a
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Datos de
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-offshore>
            <tr>
                <td>
                    <a [href]="offshore.entity_link" target="_blank">{{offshore.entity}}</a>
                </td>
                <td>
                    {{offshore.jurisdiction}}
                </td>
                <td>
                    {{offshore.linked_to}}
                </td>
                <td>
                    <a [href]="offshore.data_from_link" target="_blank">{{offshore.data_from}}</a>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
