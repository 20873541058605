import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {PermissionInterface} from "../interfaces/permission";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    constructor(private http:HttpClient) { }

    public getPermissions = (): Observable<PermissionInterface[]> => {
        return this.http.get<PermissionInterface[]>('permissions');
    }

    public createPermission = (permission: PermissionInterface): Observable<PermissionInterface> => {
        return this.http.post<PermissionInterface>('permissions', {
            name: permission.name
        });
    }

    public updatePermissions = (permission:PermissionInterface): Observable<PermissionInterface> => {

        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', permission.name);

        const headers = new HttpHeaders({});

        return this.http.post<PermissionInterface>('permissions/'+permission.uuid, formData, {
            headers: headers
        });

    }

    public deletePermission = (uuid: string): Observable<any> => {
        return this.http.delete(`permissions/${uuid}`);
    }
}
