import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SearchResponse} from "./search/search.service";
import {Observable} from "rxjs";
import {SearchCountResponse} from "../interfaces/search-count";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient) { }

    public login(userEmail: string, password: string) {
        return this.http.post<{token:string, plan: SearchCountResponse}>('auth/login', {
            'email': userEmail,
            'password': password
        });
    }

    public resetEmail(userEmail: string){
        return this.http.post<any>('auth/reset_email', {
            'email': userEmail
        });
    }

    public resetPassword(token: string, password: string){
        return this.http.post<any>('auth/reset_password', {
            'token': token,
            'password': password
        });
    }

    public logout() {
      return this.http.post('auth/logout', {});
    }

    public getSearchCount(): Observable<SearchCountResponse>{
        return this.http.get<SearchCountResponse>('auth/search_count');
    }

}
