import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {NgClass, NgIf} from "@angular/common";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {PermissionInterface} from "../../interfaces/permission";
import {PermissionsService} from "../../services/permissions.service";

@Component({
    selector: 'app-permissions',
    standalone: true,
    imports: [
        ButtonModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        NgIf,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToolbarModule,
        NgClass
    ],
    templateUrl: './permissions.component.html',
    styleUrl: './permissions.component.scss',
    providers: [MessageService]
})
export class PermissionsComponent implements OnInit{

    permissions: PermissionInterface[];
    permission: PermissionInterface;
    selectedPermissions: PermissionInterface[] = [];

    uuid: string;
    cols: any[] = [];
    form: FormGroup;

    deletePermissionsDialog: boolean = false;
    submitted: boolean = false;
    permissionDialog: boolean = false;
    editDialog: boolean = false;
    isSaving: boolean = false;

    constructor(private messageService: MessageService, private permissionService: PermissionsService) {}

    ngOnInit() {

        this.form = new FormGroup({
           name: new FormControl('')
        });

        this.loadPermissions();
    }

    hideDialog() {
        this.permissionDialog = false;
    }

    loadPermissions(){
        this.permissionService.getPermissions().subscribe(data => {
            this.permissions = data;
        });
    }

    openNew() {
        this.form.reset({
            'name': "",
        });
        this.submitted = false;
        this.permissionDialog = true;
    }

    createPermission(){
        if (this.form.invalid){
            return;
        }

        this.permissionService.createPermission(this.form.value).subscribe(data => {
            this.hideDialog();
            this.loadPermissions();
        });
    }

    editSelectedPermissions(permission:PermissionInterface) {
        this.permission = permission;
        this.submitted = false;
        this.editDialog = true;
    }

    updatePermission(){
        if (this.isSaving) return;
        this.isSaving = true;

        this.permissionService.updatePermissions(this.permission).subscribe(data => {
            console.log(data);
            this.editDialog = false;
            this.loadPermissions();
        });
    }

    deleteSelectedPermissions() {
        this.deletePermissionsDialog = true;
    }

    confirmDeleteSelected() {
        this.deletePermissionsDialog = false;
        //this.permissions = this.permissions.filter(val => !this.selectedPermissions.includes(val));

        this.selectedPermissions.forEach((permission) => {
           this.permissionService.deletePermission(permission.uuid.toString()).subscribe(()=> {
               this.loadPermissions();
               this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Permisos Eliminados', life: 3000 });
           });
        });
    }

}
