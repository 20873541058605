<p-toast [life]="5500"></p-toast>
<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Proveedor" icon="pi pi-plus"
                                class="p-button mr-2" (click)="openNew()">
                        </button>

                        <button pButton pRipple label="Eliminar Proveedor" icon="pi pi-trash"
                                class="p-button mr-2 mt-2" (click)="deleteSelectedSuppliers()">
                        </button>

                        <button pButton pRipple label="Buscar Seleccionados" icon="pi pi-search"
                                class="p-button-secondary mr-2 mt-2"
                                (click)="searchSelectedSuppliers()">
                        </button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="suppliers"
                     [columns]="cols" responsiveLayout="scroll"
                     [rows]="10"
                     [paginator]="true"
                     [(selection)]="selectedSuppliers"
                     selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Proveedores</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">Nombre o Razón Social<p-sortIcon field="name"></p-sortIcon></th>
                        <th>Acciones</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-supplier>
                    <tr>
                        <td><p-tableCheckbox [value]="supplier"></p-tableCheckbox></td>
                        <td>{{supplier.last_name}} {{supplier.name}}</td>
                        <td>
                            <p-button label="Editar" icon="pi pi-pencil"
                                      class="mr-2 mt-2" (click)="editSelectedSuppliers(supplier)"></p-button>

                            <p-button label="Buscar Proveedor" icon="pi pi-search"
                                      class="mr-2 mt-2" [loading]="supplier.loading" (click)="searchSupplier(supplier)"></p-button>

                            <p-button label="Historial" icon="pi pi-search"
                                      class="mr-2 mt-2" [routerLink]="['/suppliers', supplier.uuid, 'search_list']"></p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>


        <p-dialog [formGroup]="supplierForm"
                  [(visible)]="supplierDialog"
                  [style]="{width: '450px'}"
                  [modal]="true"
                  header="Nuevo Proveedor"
                  class="p-fluid">

            <ng-template pTemplate="content">
                <div class="field">
                    <label>Tipo de Proveedor</label>
                    <p-dropdown
                        [options]="supplierTypes"
                        formControlName="type"
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Selecciona su Tipo" />
                </div>
                <div *ngIf="supplierForm.get('type').value === 'company'" class="field">
                    <label>Razón Social</label>
                    <input type="text" pInputText formControlName="last_name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !supplierForm.get('last_name').valid}"/>
                </div>
                <div *ngIf="supplierForm.get('type').value === 'person'" class="field">
                    <label>Nombres</label>
                    <input type="text" pInputText formControlName="name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !supplierForm.get('name').valid}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !supplierForm.get('name').valid">Nombre es requerido.</small>
                </div>
                <div *ngIf="supplierForm.get('type').value === 'person'" class="field">
                    <label>Apellidos</label>
                    <input type="text" pInputText formControlName="last_name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !supplierForm.get('last_name').valid}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !supplierForm.get('last_name').valid">Apellido es requerido.</small>
                </div>
                <div class="field" *ngIf="supplierForm.get('type').value === 'person'">
                    <label>Fecha de Nacimiento</label>
                    <p-calendar
                        formControlName="dob"
                        [showIcon]="true"
                        [showOnFocus]="false"
                        dateFormat="dd/mm/yy"
                        inputId="buttondisplay"
                        appendTo="body" />
                </div>

                <div *ngIf="supplierForm.get('type').value === 'company'" class="field">
                    <label>Tipo de Identificación</label>
                    <p-dropdown
                        [options]="nuiTypes"
                        formControlName="type_nui"
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Selecciona el Tipo de Identificación" />
                </div>
                <div class="field">
                    <label>Identificación</label>
                    <input type="text" pInputText formControlName="nui"/>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="closeModal()"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="performAction()"></button>
            </ng-template>

        </p-dialog>


        <p-dialog [(visible)]="deleteSuppliersDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el proveedor?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteSuppliersDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>


    </div>
</div>
