import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {User} from "../interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

    constructor(private http: HttpClient) {}

    public currentUser = (): Observable<User> => {
        return this.http.get<User>('user');
    }

    public getUser = (uuid:string): Observable<User> => {
        return this.http.get<User>(`users/${uuid}`);
    }

    public getUsers = (options={}): Observable<{users:User[]}> => {
        let params = new HttpParams();
        for (let clave in options) {
            params = params.append(clave, options[clave]);
        }

        return this.http.get<{users:User[]}>('users', {params});
    }

    public importUser = (companyUuid: string, userUuid: string): Observable<string> => {
        return this.http.post<string>('users/import', {
            'company_uuid': companyUuid,
            'user_uuid': userUuid
        });
    }

    public deleteUser = (id: string): Observable<any> => {
        return this.http.delete(`users/${id}`);
    }

    public createUser = (user:User, company_uuid=null): Observable<User> => {
        return this.http.post<User>('users', {
            name: user.name,
            last_name: user.last_name,
            email: user.email,
            nui: user.nui,
            search_count: user.search_count,
            company_uuid: company_uuid,
            roles: user.roles
        });
    }

    public updateUser = (user:User, company_uuid=null): Observable<User> => {
        return this.http.put<User>('users/'+user.uuid, {
            name: user.name,
            last_name: user.last_name,
            email: user.email,
            nui: user.nui,
            search_count: user.search_count,
            company_uuid: company_uuid,
            roles: user.roles
        });
    }

}
