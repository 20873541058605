import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Group} from "../interfaces/groups";

@Injectable({
    providedIn: 'root'
})

export class GroupsService {

    constructor(private http: HttpClient) {}
    public getGroups = (): Observable<{groups:Group[]}> => {
        return this.http.get<{groups:Group[]}>('groups');
    }

    public deleteGroup = (id: string):Observable<any> => {
        return this.http.delete(`groups/${id}`);
    }

    public createGroup = (group:Group): Observable<Group> => {
        return this.http.post<Group>('groups', {
            name: group.name,
            registry: group.registry
        });
    }

}
