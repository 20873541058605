import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private messageService: MessageService) { }

    showWarnSearched(message: string) {
        this.messageService.add({detail: message, severity: 'success', summary: 'Búsqueda Exitosa'});
    }
    showWarnIncorrectEmailOrPass(message: string) {
        this.messageService.add({detail: message, severity: 'warn', summary: 'Acceso'});
    }
    showWarnEmailOrPassMandatory(message: string) {
        this.messageService.add({detail: message, severity: 'warn', summary: 'Acceso'});
    }
    showSuccessChangeUserPassword(message: string) {
        this.messageService.add({detail: message, severity: 'success', summary: 'Cambio Exitoso'});
    }
    showSuccessRecoveryPass(message: string) {
        this.messageService.add({detail: message, severity: 'success', summary: 'Revise su correo electrónico'});
    }

}
