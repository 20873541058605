import { Injectable } from '@angular/core';
import { Item } from "../interfaces/item";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

    constructor(private http: HttpClient) {}

    public getItems = (uuid:string): Observable<Item[]> => {
        return this.http.get<Item[]>(`black_list/${uuid}/items`);
    }

    public deleteItem = (listUuid: string, uuid: string): Observable<any> => {
        return this.http.delete(`black_list/${listUuid}/items/${uuid}`);
    }

    public updateItem = (item:Item, uuid:string): Observable<Item> => {
        return this.http.put<Item>(`black_list/${uuid}/items`, {
            name: item.name,
            last_name: item.last_name,
            nui: item.nui,
            details: item.details
        });
    }

    public createItem = (item:Item, uuid:string): Observable<Item> => {
        return this.http.post<Item>(`black_list/${uuid}/items`, {
            name: item.name,
            last_name: item.last_name,
            nui: item.nui,
            details: item.details
        });
    }

}
