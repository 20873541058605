import { Component } from '@angular/core';
import { ButtonModule } from "primeng/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { LayoutService } from "../../../layout/service/app.layout.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NotificationService } from "../../../services/notification-service.service";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";

@Component({
    selector: 'app-reset-email',
    standalone: true,
    imports: [
        ButtonModule,
        ReactiveFormsModule,
        InputTextModule,
        ProgressSpinnerModule,
        ToastModule
    ],
    providers: [NotificationService, MessageService],
    templateUrl: './reset-email.component.html',
    styleUrl: './reset-email.component.scss'
})

export class ResetEmailComponent {
    email!: string;
    form: FormGroup;
    private http: any;
    isLoading: boolean = false;

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService
    ) {
        this.form = new FormGroup({
            email: new FormControl('', Validators.required),
        });
    }


    protected readonly ResetEmailComponent = ResetEmailComponent;

    resetEmail = ()=> {

        if (this.form.invalid) {
            this.isLoading = false;
            this.notificationService.showWarnEmailOrPassMandatory('El campo de email es obligatorio.');
            return;
        }

        this.isLoading = true;
        this.authService.resetEmail(
            this.form.get('email').value
        ).subscribe(data => {
            this.notificationService.showSuccessChangeUserPassword('Los pasos para el cambio de contraseña han sido enviados, por favor revise su correo electrónico.');
            setTimeout(()=>{
                this.isLoading = false;
                this.router.navigate(['/login']).then();
            },5600);
        });
    }

}
