<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="records" rows="10" paginator="true">

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Cargo
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Lugar de trabajo
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre de la Institución
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    {{record.name}}
                </td>
                <td>
                    {{record.charge.cargo}}
                </td>
                <td>
                    {{record.working_place.place}}
                </td>
                <td>
                    {{record.institution.name}}
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
