<div class="grid">
    <div class="col-3 p-2">
        <h5>Información Personal</h5>
        <p-divider />
        <b>Nombre: </b>{{notice.name}}, {{notice.forename}}<br>
        <b>Género: </b>{{notice.sex_id}}<br>
        <b>Fecha de Nacimiento: </b>{{notice.date_of_birth}}<br>
        <b>Lugar de Nacimiento: </b>{{notice.place_of_birth}}<br>
        <b>Nacionalidad: </b>{{notice.nationalities}}
        <p-divider />
        <b>Marcas de distinción y características: </b>{{notice.distinguishing_marks}}<br>
        <b>Altura: </b>{{notice.height}}<br>
        <b>Color de ojos: </b>{{notice.hairs_id}}<br>
        <b>Idiomas: </b>{{notice.languages_spoken_ids}}
    </div>

    <div class="col-9 p-2">
        <div class="p-card-title font-bold"><h5>Arrestos</h5></div>
        <p-divider />

        <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="notice.arrest_warrants">

            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            Pais
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            Cargo
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-arrest>
                <tr>
                    <td>
                        {{arrest.issuing_country_id}}
                    </td>
                    <td>
                        <span class="image-text ml-2">{{arrest.charge}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>
