<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Usuario" icon="pi pi-plus" class="p-button mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Usuario" icon="pi pi-trash" class="p-button mr-2 mt-2" (click)="deleteSelectedUsers()"></button>
                        <button *ngIf="currentUser.permissions.includes('Users import')" pButton pRipple label="Importar Usuario" icon="pi pi-window-minimize" class="p-button-secondary mr-2 mt-2" (click)="showImportUser()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="users" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedUsers" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Usuarios</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="last_name">Apellido <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="email">e-Mail <p-sortIcon field="email"></p-sortIcon></th>
                        <th pSortableColumn="nui">Identificación <p-sortIcon field="nui"></p-sortIcon></th>
                        <th *ngIf="currentUser?.permissions.includes('Users Set Search')">
                            Búsquedas Contratadas
                        </th>
                        <th>Búsquedas Realizadas </th>
                        <th>Acciones </th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td><p-tableCheckbox [value]="user"></p-tableCheckbox></td>
                        <td>{{user.name}}</td>
                        <td>{{user.last_name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.nui}}</td>
                        <td *ngIf="currentUser?.permissions.includes('Users Set Search')">
                            <app-update-user-count [userc] = "user"
                                                   (changeSearchCount) = "changeSearchCount($event)">
                            </app-update-user-count>
                        </td>
                        <td>{{user?.count}} </td>
                        <td>
                            <button pButton pRipple (click)="editSelectedUser(user)" label="Editar" class="p-button-secondary mr-2 mt-2" icon="pi pi-pencil"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>


        <p-dialog [formGroup]="form" [(visible)]="userDialog" [style]="{width: '650px'}" header="Nuevo Usuario" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <div class="p-grid">
                    <div class="p-col-12 p-md-6">
                        <div class="field">
                            <label for="name">Nombre</label>
                            <input formControlName="name" type="text" pInputText id="name" required autofocus
                                   [ngClass]="{'ng-invalid ng-dirty' : form.get('name').touched && form.get('name').invalid}"/>
                            <small class="ng-dirty ng-invalid" *ngIf="form.get('name').touched && form.get('name').invalid">Nombre es requerido.</small>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <div class="field">
                            <label for="last_name">Apellido</label>
                            <input formControlName="last_name" type="text" pInputText id="last_name" required autofocus
                                   [ngClass]="{'ng-invalid ng-dirty' : form.get('last_name').touched && form.get('last_name').invalid}"/>
                            <small class="ng-dirty ng-invalid" *ngIf="form.get('last_name').touched && form.get('last_name').invalid">Apellido es requerido.</small>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <div class="field">
                            <label for="roles">Roles</label>
                            <p-multiSelect
                                [options]="roles"
                                optionValue="uuid"
                                formControlName="roles"
                                optionLabel="name"
                                placeholder="Seleccionar Rol" />
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <div class="field">
                            <label for="email">e-Mail</label>
                            <input formControlName="email" type="text" pInputText id="email" required autofocus
                                   [ngClass]="{'ng-invalid ng-dirty' : form.get('email').touched && form.get('email').invalid}"/>
                            <small class="ng-dirty ng-invalid" *ngIf="form.get('email').touched && form.get('email').invalid">e-Mail es requerido.</small>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <div class="field">
                            <label for="nui">Número de Identificación</label>
                            <input formControlName="nui" type="text" pInputText id="nui" />
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="closeModal()"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="performAction()"></button>
            </ng-template>
        </p-dialog>


        <p-dialog [(visible)]="importDialog" [style]="{width: '450px', height: '250px'}" header="Importar Usuario" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <div class="field">
                    <p-autoComplete
                        [(ngModel)]="selectedUser"
                        [dropdown]="true"
                        [suggestions]="filteredUsers"
                        (completeMethod)="filterUser($event)"
                        field="name" />
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" (click)="closeModal()" icon="pi pi-times" class="p-button-text"></button>
                <button pButton pRipple label="Importar Usuario" (click)="importUser()" icon="pi pi-check" class="p-button-text"></button>
            </ng-template>
        </p-dialog>


        <p-dialog [(visible)]="deleteUsersDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el usuario?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="No" (click)="deleteUsersDialog = false" icon="pi pi-times" class="p-button-text"></button>
                <button pButton pRipple label="Si" (click)="confirmDeleteSelected()" icon="pi pi-check" class="p-button-text"></button>
            </ng-template>
        </p-dialog>


    </div>
</div>
