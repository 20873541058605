import {Component, OnDestroy, OnInit} from '@angular/core';
import {Instance, SearchResponse, SearchService} from "../../../services/search/search.service";
import {ActivatedRoute} from "@angular/router";
import {AccordionModule} from "primeng/accordion";
import {AvatarModule} from "primeng/avatar";
import {BadgeModule} from "primeng/badge";
import {ButtonModule} from "primeng/button";
import {DividerModule} from "primeng/divider";
import {InputTextModule} from "primeng/inputtext";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "primeng/api";
import {Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {InterpolDisplayComponent} from "../../../components/interpol-display/interpol-display.component";
import {CausasDisplayComponent} from "../../../components/causas-display/causas-display.component";
import {PenasDisplayComponent} from "../../../components/penas-display/penas-display.component";
import {OfacDisplayComponent} from "../../../components/ofac-display/ofac-display.component";
import {PepsDisplayComponent} from "../../../components/peps-display/peps-display.component";
import {NnuuDisplayComponent} from "../../../components/nnuu-display/nnuu-display.component";
import {NnuuEntityDisplayComponent} from "../../../components/nnuu-entity-display/nnuu-entity-display.component";
import {MasBuscadosEcDisplayComponent} from "../../../components/mas-buscados-ec-display/mas-buscados-ec-display.component";
import {CneDisplayComponent} from "../../../components/cne-display/cne-display.component";
import {ShellCompaniesDisplayComponent} from "../../../components/shell-companies-display/shell-companies-display.component";
import {LaLinkDirective} from "../../../directives/la-link.directive";
import {ReportLinkDirective} from "../../../directives/report-link.directive";
import {BlackListDisplayComponent} from "../../../components/black-list-display/black-list-display.component";
import {IessDisplayComponent} from "../../../components/iess-display/iess-display.component";
import {FgeNoticesDisplayComponent} from "../../../components/fge-notices-display/fge-notices-display.component";
import {OffshoreDisplayComponent} from "../../../components/offshore-display/offshore-display.component";

@Component({
    selector: 'app-show',
    standalone: true,
    imports: [
        AccordionModule,
        AvatarModule,
        BadgeModule,
        ButtonModule,
        DividerModule,
        InputTextModule,
        NgForOf,
        NgIf,
        PaginatorModule,
        ReactiveFormsModule,
        SharedModule,
        JsonPipe,
        ProgressSpinnerModule,
        InterpolDisplayComponent,
        CausasDisplayComponent,
        PenasDisplayComponent,
        OfacDisplayComponent,
        PepsDisplayComponent,
        NnuuDisplayComponent,
        NnuuEntityDisplayComponent,
        MasBuscadosEcDisplayComponent,
        CneDisplayComponent,
        LaLinkDirective,
        ReportLinkDirective,
        ShellCompaniesDisplayComponent,
        BlackListDisplayComponent,
        IessDisplayComponent,
        FgeNoticesDisplayComponent,
        OffshoreDisplayComponent
    ],
    templateUrl: './show.component.html',
    styleUrl: './show.component.scss'
})
export class ShowComponent implements OnInit, OnDestroy{

    routeSubscription: Subscription;
    searchResponse: SearchResponse;
    instances: Instance[];
    uuid: string;
    searchCompleted: boolean = false;
    load: boolean = false;
    intervalID: number;


    constructor(private route: ActivatedRoute, private searchService: SearchService) {}
    ngOnInit() {
       this.intervalID = undefined;
       this.routeSubscription = this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
       });
        this.ticker();
    }

    loadInstances() {
        this.searchService.get(this.uuid).subscribe(searchResponse => {
            this.instances = searchResponse.instance_responses;
            let searchCompleted: boolean = true;


            for (let instance in this.searchResponse.instances) {
                const instanceResponse = this.instances?.find(instanceResponse => {
                    return instanceResponse.instance === instance;
                });

                if (instanceResponse !== null && !instanceResponse?.completed) {
                    searchCompleted = false;
                }
            }

            this.searchCompleted = searchCompleted;

            if(!this.searchResponse.report) {
                this.ticker();
            }

        });
    }
    loadSearch(){
        if (!this.load) {
            this.load = true;
        }else{
            return;
        }

        this.searchService.get(this.uuid).subscribe(searchResponse => {
            this.searchResponse = searchResponse;
            this.load = false;
            this.loadInstances();
        });
    }

    ticker() {
        if(!this.uuid) {
            this.ticker();
            return;
        }

        console.log(this.intervalID);

       if (this.intervalID) {
           // @ts-ignore
           this.intervalID = setTimeout(()=>{this.loadSearch();}, 4000);
       } else {
           this.intervalID = 1;
           this.loadSearch();
       }
    }


    ngOnDestroy() {

        console.log('destroy');

        clearTimeout(this.intervalID);
        this.routeSubscription.unsubscribe();
    }

    getInstance(instanceName: string): Instance | null {

        if (!this.instances) {
            return null;
        }

        return this.instances?.find(instance=> {
            return instance.instance === instanceName;
        });
    }

    protected readonly environment = environment;
}
