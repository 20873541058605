import {Component, Input} from '@angular/core';
import {MasBuscadosEc} from "../../interfaces/mas-buscados-ec/mas-buscados-ec";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";

@Component({
  selector: 'app-mas-buscados-ec-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
  templateUrl: './mas-buscados-ec-display.component.html',
  styleUrl: './mas-buscados-ec-display.component.scss'
})
export class MasBuscadosEcDisplayComponent {

    @Input() records: MasBuscadosEc[] = [];

}
