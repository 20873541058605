import {Component, OnInit} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DialogModule} from "primeng/dialog";
import {FormsModule} from "@angular/forms";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {Group} from "../../interfaces/groups";
import {ToolbarModule} from "primeng/toolbar";
import {TableModule} from "primeng/table";
import {NgIf} from "@angular/common";
import {MessageService} from "primeng/api";
import {GroupsService} from "../../services/groups.service";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-groups',
    standalone: true,
    imports: [
        InputTextModule,
        ButtonModule,
        RippleModule,
        DialogModule,
        FormsModule,
        InputGroupAddonModule,
        InputGroupModule,
        ToolbarModule,
        TableModule,
        NgIf,
        RouterLink
    ],
    templateUrl: './groups.component.html',
    styleUrl: './groups.component.scss',
    providers: [MessageService]
})

export class GroupsComponent implements OnInit{

    groups: Group[];
    group: Group;
    selectedGroups: Group[] = [];

    submitted : boolean = false;
    groupDialog : boolean = false;
    deleteGroupsDialog : boolean = false;
    cols: any[] = [];

    constructor(private groupService: GroupsService, private messageService: MessageService) {}

    ngOnInit() {
        this.loadGroups();
    }

    loadGroups(){
        this.groupService.getGroups().subscribe(data => {
            this.groups = data.groups;
        });
    }

    openNew(){
        this.group = {
            name: "",
            registry: ""
        } as Group;
        this.submitted = false;
        this.groupDialog = true;
    }

    createGroup(){
        this.groupService.createGroup(this.group).subscribe(data => {
            console.log(data);
            this.hideDialog();
            this.loadGroups();
        });
    }

    hideDialog() {
        this.groupDialog = false;
        this.submitted = false;
    }

    deleteSelectedGroups() {
        this.deleteGroupsDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteGroupsDialog = false;
        this.groups = this.groups.filter(val => !this.selectedGroups.includes(val));
        this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Listas Eliminadas', life: 3000 })

        this.selectedGroups.forEach((val) => {
            this.groupService.deleteGroup(val.uuid.toString()).subscribe(()=> {
                this.loadGroups();
            });
        });
        this.selectedGroups = [];
    }

}
