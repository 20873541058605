<div class="layout-topbar">

    <!--<a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo">
        <span>SAKAI</span>
    </a>-->

    <a class="layout-topbar-logo"
       *ngIf="searchCount"
       [routerLink]="searchCount.plan_type === 'Clientes' ? '/clients' : '/search'">
        <img src="assets/logos/APLASoft.png" alt="APLASoft">
        <!--<span>SAKAI</span>-->
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <!--<button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button>-->

        <div><i class="pi pi-info-circle mt-3"></i><span class="ml-1"> {{ searchCount?.busquedas_realizadas }} {{ searchCount?.plan_type }} de {{ searchCount?.busquedas_contratadas }} </span></div>

        <button class="p-link layout-topbar-button" (click)="logout()">
            <i class="pi pi-sign-in"></i>
            <span>Log Out</span>
        </button>

        <!--<button class="p-link layout-topbar-button" [routerLink]="'/documentation'">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button>-->

    </div>
</div>
