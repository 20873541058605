import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { SearchComponent } from './pages/search/search.component';
import {UsersComponent} from "./pages/users/users.component";
import {GroupsComponent} from "./pages/groups/groups.component";
import {ShowComponent} from "./pages/search/show/show.component";
import {LoginComponent} from "./pages/auth/login/login.component";
import {authGuard} from "./guards/auth.guard";
import {ResetComponent} from "./pages/auth/reset/reset.component";
import {ResetEmailComponent} from "./pages/auth/reset-email/reset-email.component";
import {BatchSearchComponent} from "./pages/batch-search/batch-search.component";
import {BatchSearchListComponent} from "./pages/batch-search/batch-search-list/batch-search-list.component";
import { ListsComponent } from "./pages/lists/lists/lists.component";
import { ItemsListComponent } from "./pages/lists/items-list/items-list.component";
import {CompaniesComponent} from "./pages/companies/companies.component";
import {PlansComponent} from "./pages/plans/plans.component";
import {PermissionsComponent} from "./pages/permissions/permissions.component";
import {RolesComponent} from "./pages/roles/roles.component";
import {ClientsComponent} from "./pages/clients/clients.component";
import {SearchListComponent} from "./pages/clients/search-list/search-list.component";
import {SuppliersComponent} from "./pages/suppliers/suppliers.component";
import {EmployeesComponent} from "./pages/employees/employees.component";


@NgModule({
    imports: [
        RouterModule.forRoot([

            {
                path: '', component: AppLayoutComponent,
                children: [
                    { path: 'batch_search', component: BatchSearchComponent },
                    { path: 'batch_search/:uuid', component: BatchSearchListComponent },
                    { path: 'batch_search/:batchId/search/:uuid', component: ShowComponent },
                    { path: 'company', component: CompaniesComponent },
                    { path: 'groups', component: GroupsComponent },
                    { path: 'groups/:uuid/companies', component: CompaniesComponent },
                    { path: 'groups/:guuid/companies/:cuuid/users', component: UsersComponent },
                    { path: 'lists', component: ListsComponent },
                    { path: 'lists/:uuid/items', component: ItemsListComponent },
                    { path: 'groups/:guuid/companies/:cuuid/plans', component: PlansComponent },
                    { path: 'permissions', component: PermissionsComponent },
                    { path: 'roles', component: RolesComponent },
                    { path: 'search/:uuid', component: ShowComponent },
                    { path: 'search', component: SearchComponent },
                    { path: 'users', component: UsersComponent },
                    { path: ':resource/:uuid/search_list', component: SearchListComponent },
                    { path: 'clients', component: ClientsComponent },
                    { path: 'suppliers', component: SuppliersComponent },
                    { path: 'employees', component: EmployeesComponent },
                    { path: '', loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'uikit', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
                    { path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    { path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    { path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    { path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) }
                ],
                canActivate: [authGuard]
            },
            { path: 'auth', loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
            { path: 'login', component: LoginComponent},
            { path: 'reset_email', component: ResetEmailComponent},
            { path: 'password_reset', component: ResetComponent},
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
