import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

    constructor(private http: HttpClient) { }

    /**
     *
     * @param name
     * @param lastName
     * @param id
     * @param dob
     */
    public search = (name: string, lastName: string, id: string, dob: string): Observable<SearchResponse> => {
        return this.http.post<SearchResponse>('search', {
            'name': name,
            'last_name': lastName,
            'id': id,
            'dob': dob,
        });
    }


    /**
     *
     * @param uuid
     */
    public get = (uuid: string): Observable<SearchResponse> => {
        return this.http.get<SearchResponse>(`search/${uuid}`);
    }

    public loadSearch = (batchID: string = null): Observable<SearchResponse[]> => {

        let params = new HttpParams();
        if (batchID) {
            params = params.set('batchID', batchID);
        }

        return this.http.get<SearchResponse[]>(`search`, {params});
    }

}

export interface SearchResponse {
    uuid: string;
    query: SearchQuery;
    report: string|null;
    dob: string;
    user_id: number;
    updated_at: string;
    created_at: string;
    instances: string[];
    instance_responses: Instance[];
}

export interface SearchQuery {
    name: string,
    last_name: string,
    id: string,
    dob: string
}

export interface Instance {
    instance: string;
    completed: boolean;
    response: any|null;
    files: string[]
}
