import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {NnuuEntity} from "../../interfaces/nnuu-entity/nnuu-entity";

@Component({
  selector: 'app-nnuu-entity-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
  templateUrl: './nnuu-entity-display.component.html',
  styleUrl: './nnuu-entity-display.component.scss'
})
export class NnuuEntityDisplayComponent {

    @Input() records: NnuuEntity[] = [];

}
