import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {DividerModule} from "primeng/divider";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InterpolDisplayComponent} from "../../../components/interpol-display/interpol-display.component";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {AccordionModule} from "primeng/accordion";
import {AvatarModule} from "primeng/avatar";
import {BadgeModule} from "primeng/badge";
import {CausasDisplayComponent} from "../../../components/causas-display/causas-display.component";
import {PenasDisplayComponent} from "../../../components/penas-display/penas-display.component";
import {SearchResponse, SearchService} from "../../../services/search/search.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {Store} from "@ngxs/store";
import {LoadCurrentUserAction} from "../../../store/user/store/user.actions";
import {UserState} from "../../../store/user/store/user.state";
import {Observable, Subscription} from "rxjs";
import {User} from "../../../interfaces/user";
import {TabViewModule} from "primeng/tabview";

@Component({
    standalone: true,
    imports: [
        InputTextModule,
        ButtonModule,
        InterpolDisplayComponent,
        DividerModule,
        ReactiveFormsModule,
        NgForOf,
        AccordionModule,
        AvatarModule,
        BadgeModule,
        NgClass,
        NgIf,
        CausasDisplayComponent,
        PenasDisplayComponent,
        TableModule,
        RouterLink,
        DatePipe,
        RippleModule,
        TabViewModule
    ],
    templateUrl: './batch-search-list.component.html',
    styleUrl: './batch-search-list.component.scss'
})
export class BatchSearchListComponent implements OnInit, OnDestroy {

    form: FormGroup;
    batchSearch: SearchResponse[];
    user$: Observable<User> = inject(Store).select(UserState.currenUser);
    user: User;
    userSubscription: Subscription;
    activeIndex: number = 0;
    routeSubscription: Subscription;
    batchUuid: string;

    constructor(private searchService: SearchService ,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private store:Store) {}

    ngOnInit() {

        this.routeSubscription = this.route.params.subscribe(params => {
            this.batchUuid = params['uuid'];
            this.loadSearch();
        });

        this.form = this.fb.group({
            'name': [''],
            'last_name': [''],
            'id': [''],
        });

        this.userSubscription = this.user$.subscribe(user => {
            if(user.email.length === 0) {
                this.store.dispatch(new LoadCurrentUserAction());
                return;
            }

            this.user = user;
        });
    }

    loadSearch(): void {
        this.searchService.loadSearch(this.batchUuid).subscribe(batchSearch => {
            this.batchSearch = batchSearch;
        });
    }

    search = () => {
        const name = this.form.get('name').value;
        const lastName = this.form.get('last_name').value;
        const id = this.form.get('id').value;

        this.searchService.search(name, lastName, id).subscribe(searchResponse => {
            this.store.dispatch(new LoadCurrentUserAction());
            //this.router.navigate(['search', searchResponse.uuid]).then();
        });
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }
}
