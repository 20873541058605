import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {PasswordModule} from "primeng/password";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LayoutService} from "../../../layout/service/app.layout.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {NotificationService} from "../../../services/notification-service.service";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";

@Component({
    selector: 'app-reset',
    standalone: true,
    imports: [
        ButtonModule,
        PasswordModule,
        ProgressSpinnerModule,
        ReactiveFormsModule,
        ToastModule
    ],
    providers: [NotificationService, MessageService],
    templateUrl: './reset.component.html',
    styleUrl: './reset.component.scss'
})
export class ResetComponent implements OnInit{

    form: FormGroup;
    token: string;
    isLoading: boolean = false;

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService
    ) {
        this.form = new FormGroup({
            password: new FormControl('', Validators.required),
            confirmPassword: new FormControl('', Validators.required),
        });
    }

    protected readonly ResetComponent = ResetComponent;

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
           this.token = params['token'];
        });
    }

    changePassword = ()=> {

        if (this.form.invalid) {
            this.isLoading = false;
            this.notificationService.showWarnEmailOrPassMandatory('Los campos deben contener datos.');
            return;
        }

        this.authService.resetPassword(this.token, this.form.get('password').value).subscribe(data => {
            this.isLoading = true;
            this.notificationService.showSuccessChangeUserPassword('Tu contraseña ha sido cambiada');
            setTimeout(()=>{
                this.isLoading = false;
                this.router.navigate(['/login']).then();
            },5600);
        });
    }

}
