<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Permiso" icon="pi pi-plus" class="p-button mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Permiso" icon="pi pi-trash" class="p-button mr-2" (click)="deleteSelectedPermissions()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="permissions" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedPermissions" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Permisos</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="typePermission">Nombre de Permiso <p-sortIcon field="typePermission"></p-sortIcon></th>
                        <th pSortableColumn="typePermission">Acciones <p-sortIcon field="typePermission"></p-sortIcon></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-permission>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="permission"></p-tableCheckbox>
                        </td>
                        <td><span class="p-column-title">Nombre</span>
                            {{permission.name}}
                        </td>
                        <td>
                            <button pButton pRipple label="Editar" icon="pi pi-pencil" class="p-button-secondary mr-2 mt-2" (click)="editSelectedPermissions(permission)"></button>
                        </td>
                    </tr>
                </ng-template>

            </p-table>

        </div>

        <p-dialog [formGroup]="form" [(visible)]="permissionDialog" [style]="{width: '450px'}" header="Nuevo Permiso" [modal]="true" class="p-fluid">
            <div class="field">
                <label>Permiso:</label>
                <input type="text" pInputText formControlName="name" placeholder="Nombre del Permiso">
            </div>

            <p-footer>
                <button pButton type="button" label="Guardar" icon="pi pi-check" (click)="createPermission()"></button>
                <button pButton type="button" label="Cancelar" icon="pi pi-times" class="p-button-secondary" (click)="hideDialog()"></button>
            </p-footer>
        </p-dialog>

        <p-dialog [(visible)]="editDialog" [style]="{width: '450px'}" header="Edición del Permiso" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <div class="form-grid">
                    <div class="field">
                        <label for="name">Nombre</label>
                        <input type="text" pInputText id="name" [(ngModel)]="permission!.name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !permission!.name}"/>
                        <small class="ng-dirty ng-invalid" *ngIf="submitted && !permission!.name">Nombre es requerido.</small>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="updatePermission()" [disabled]="isSaving"></button>
            </ng-template>

        </p-dialog>

        <p-dialog [(visible)]="deletePermissionsDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el permiso?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deletePermissionsDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>
