<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="records" rows="10" paginator="true">

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre Completo
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nacionalidad
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Fecha de Nacimiento
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Lugar de Nacimiento
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Comentario
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    {{record.name}}
                </td>
                <td>
                    {{record.nationality}}
                </td>
                <td>
                    {{record.dob.TYPE_OF_DATE}} {{record.dob.YEAR}}
                </td>
                <td>
                    {{record.pob.CITY}} {{record.pob.STATE_PROVINCE}} {{record.pob.COUNTRY}}
                </td>
                <td [innerHTML]="record.comments">
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
