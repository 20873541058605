<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-table [value]="client?.search_records"
                     [columns]="cols"
                     responsiveLayout="scroll"
                     [rows]="10"
                     [paginator]="true"
                     [(selection)]="selectedClients"
                     selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Historial de Búsquedas</h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">

                    <tr>
                        <th>Nombre</th>
                        <th>Identificación</th>
                        <th>Creación</th>
                        <th>Acciones</th>
                    </tr>

                </ng-template>
                <ng-template pTemplate="body" let-search>

                    <tr>
                        <td>{{search.query.name}} {{search.query.last_name}}</td>
                        <td>{{search.query.id}}</td>
                        <td>{{search.created_at | date: 'yyyy/MM/dd hh:mm a'}}</td>
                        <td>
                            <button pButton pRipple label="Detalles"
                                    icon="pi pi-angle-double-right"
                                    iconPos="right"
                                    class="p-button-raised p-button-green mr-3  "
                                    [routerLink]="['/search', search.uuid]">
                            </button>

                            <a *ngIf="!!search?.report" target="_blank"
                               [reportLink]="search.report"
                               [uuid]="search.uuid"
                               href="{{search.report}}">
                                <p-button label="Descargar Informe"
                                          icon="pi pi-download"
                                          class="p-button-warning"
                                          severity="success">
                                </p-button>
                            </a>
                        </td>
                    </tr>

                </ng-template>
            </p-table>

        </div>
    </div>
</div>
