import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchSearch} from "../../interfaces/search";

@Injectable({
  providedIn: 'root'
})
export class BatchSearchService {

    constructor(private http: HttpClient) {}

    getList(): Observable<BatchSearch[]> {
        return this.http.get<BatchSearch[]>('batch_search');
    }

    createBatchSearch(name: string, file: File): Observable<BatchSearch> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        const headers = new HttpHeaders({});

        return this.http.post<BatchSearch>('batch_search', formData, {
            headers: headers
        });
    }
}

