<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Rol" icon="pi pi-plus" class="p-button mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Rol" icon="pi pi-trash" class="p-button mr-2" (click)="deleteSelectedRoles()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="roles" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedRoles" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Roles</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" placeholder="Buscar..." class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="typeRole">Nombre del Rol <p-sortIcon field="typeRole"></p-sortIcon></th>
                        <th>Acciones </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-role>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="role"></p-tableCheckbox>
                        </td>
                        <td><span class="p-column-title">Nombre</span>
                            {{role.name}}
                        </td>
                        <td>
                            <button pButton pRipple label="Editar" icon="pi pi-pencil" class="p-button-secondary mr-2 mt-2" (click)="editSelectedRoles(role)"></button>
                        </td>
                    </tr>
                </ng-template>

            </p-table>

        </div>

        <p-dialog [formGroup]="form" [(visible)]="roleDialog" [style]="{width: '700px'}" header="Nuevo Rol" [modal]="true" class="p-fluid">
            <div class="field">
                <label>Nombre del Rol:</label>
                <input type="text" pInputText formControlName="name" placeholder="Nombre del Rol">
            </div>

            <div class="field">
                <p-pickList
                    [source]="getFilteredSourceItems()"
                    [target]="selectedPermissions"
                    sourceHeader="Permisos Disponibles"
                    targetHeader="Permisos Asignados"
                    [dragdrop]="true"
                    [responsive]="true"
                    [sourceStyle]="{ height: '20rem' }"
                    [targetStyle]="{ height: '20rem' }"
                    breakpoint="1400px">
                        <ng-template let-per pTemplate="item">
                            {{per.name}}
                        </ng-template>
                </p-pickList>
            </div>

            <p-footer>
                <button pButton type="button" label="Guardar" icon="pi pi-check" (click)="performAction()"></button>
                <button pButton type="button" label="Cancelar" icon="pi pi-times" class="p-button-secondary" (click)="hideDialog()"></button>
            </p-footer>
        </p-dialog>

        <p-dialog [(visible)]="deleteRolesDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el rol?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteRolesDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>
