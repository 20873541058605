import {Component, Input} from '@angular/core';
import {Notice} from "../../interfaces/interpol/interpol";
import {NgForOf} from "@angular/common";
import {TableModule} from "primeng/table";
import {DividerModule} from "primeng/divider";

@Component({
    selector: 'app-interpol-display',
    standalone: true,
    imports: [
        NgForOf,
        TableModule,
        DividerModule
    ],
  templateUrl: './interpol-display.component.html',
  styleUrl: './interpol-display.component.scss'
})
export class InterpolDisplayComponent {
    @Input() notice: Notice;
}
