import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DatePipe, NgIf, NgClass} from "@angular/common";
import {DividerModule} from "primeng/divider";
import {InputTextModule} from "primeng/inputtext";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {TabViewModule} from "primeng/tabview";
import {TableModule} from "primeng/table";
import {FileUploadModule} from "primeng/fileupload";
import {BatchSearchService} from "../../services/search/batch-search.service";
import {BatchSearch} from "../../interfaces/search";
import {RouterLink} from "@angular/router";
import {TagModule} from "primeng/tag";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {LoadSearchCountAction} from "../../store/user/search-count/search-count.actions";
import {Store} from "@ngxs/store";
import {Observable, of} from "rxjs";
import {SearchCountResponse} from "../../interfaces/search-count";
import {SearchCountState} from "../../store/user/search-count/search-count.state";
import {NotificationService} from "../../services/notification-service.service";
import {catchError} from "rxjs/operators";
import {ToastModule} from "primeng/toast";

@Component({
    selector: 'app-batch-search',
    standalone: true,
    imports: [
        ButtonModule,
        DatePipe,
        DividerModule,
        InputTextModule,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TabViewModule,
        TableModule,
        FileUploadModule,
        RouterLink,
        NgClass,
        RouterLink,
        TagModule,
        ProgressSpinnerModule,
        NgIf,
        ToastModule
    ],
    templateUrl: './batch-search.component.html',
    styleUrl: './batch-search.component.scss',
    providers: [NotificationService, MessageService]
})
export class BatchSearchComponent implements OnInit{
    searchList: BatchSearch[] = [];
    file: File = undefined;
    form: FormGroup = undefined;
    loading: boolean = false;
    isButtonDisabled: boolean = false;

    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    @ViewChild('file') inputFile: ElementRef;

    constructor(private batchSearchService: BatchSearchService,
                private store: Store,
                private notificationService: NotificationService)
    {}

    ngOnInit() {
        this.store.dispatch(new LoadSearchCountAction());
        this.searchCount$.subscribe(count=> {
            this.searchCount = count;
        });
        this.loadList();
        this.form = new FormGroup({
            name: new FormControl('', [Validators.required])
        });
    }

    loadList() {
        this.batchSearchService.getList().subscribe(list => {
            this.searchList = list;
            this.form.get('name').reset();
        });
    }

    selectFile(event: Event) {
        // @ts-ignore
        this.file = event.target.files[0];
    }

    searchBatch() {

        this.form.markAsTouched();
        this.form.markAllAsTouched();
        this.form.markAsDirty();

        if (this.form.valid) {
            this.loading = true;
            this.isButtonDisabled = true;

            this.batchSearchService.createBatchSearch(
                this.form.get('name').value,
                this.file
            ).pipe(
                catchError(error => {
                    this.notificationService.showWarnIncorrectEmailOrPass('La cantidad de búsquedas contratadas ha sido superada.');
                    return of(null);
                })
            ).subscribe(data => {
                setTimeout(() => {
                    this.store.dispatch(new LoadSearchCountAction());
                }, 2000);
                this.form.reset();
                this.inputFile.nativeElement.value = '';
                this.file = undefined;
                this.loadList();
                this.isButtonDisabled = false;
                this.loading = false;
            });
        }
    }

}
