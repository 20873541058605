import {Component, Input} from '@angular/core';
import {Ofac} from "../../interfaces/ofac/ofac";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";

@Component({
  selector: 'app-ofac-display',
  standalone: true,
  imports: [
      SharedModule,
      TableModule
  ],
  templateUrl: './ofac-display.component.html',
  styleUrl: './ofac-display.component.scss'
})
export class OfacDisplayComponent {

    @Input() records: Ofac[] = [];

}
