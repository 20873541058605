import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {RoleInterface} from "../interfaces/role";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor(private http: HttpClient) { }

    public getRoles = ():Observable<RoleInterface[]> => {
        return this.http.get<RoleInterface[]>('roles');
    }

    public createRole = (role: RoleInterface, permissions: string[]): Observable<RoleInterface> => {
        return this.http.post<RoleInterface>('roles', {
            name: role.name,
            permissions: permissions
        });

    }

    public updateRoles = (role:RoleInterface, permissions: string[]): Observable<RoleInterface> => {
        return this.http.put<RoleInterface>('roles/'+role.uuid, {
            name: role.name,
            permissions: permissions
        });
    }

    public deleteRole = (uuid: string): Observable<any> => {
        return this.http.delete(`roles/${uuid}`);
    }

}
