import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {Item} from "../../interfaces/item";

@Component({
  selector: 'app-black-list-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
  templateUrl: './black-list-display.component.html',
  styleUrl: './black-list-display.component.scss'
})
export class BlackListDisplayComponent {
    @Input() records: Item[] = [];
}
