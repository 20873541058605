import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import {AuthService} from "../services/auth.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {SearchCountResponse} from "../interfaces/search-count";
import {Store} from "@ngxs/store";
import {SearchCountState} from "../store/user/search-count/search-count.state";
import {LoadSearchCountAction} from "../store/user/search-count/search-count.actions";


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit{

    items!: MenuItem[];
    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService, private authService: AuthService, private router: Router, private store: Store) { }

    ngOnInit() {
        this.store.dispatch(new LoadSearchCountAction());
        this.searchCount$.subscribe(count=> {
            this.searchCount = count;
        });
    }

    logout = ()=> {
        this.authService.logout().subscribe(
            data => {
                sessionStorage.removeItem("usrToken");
                this.router.navigate(['/login']).then();
            }
        );
    }

}
