import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ClientInterface} from "../interfaces/client";

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

    constructor(private http: HttpClient) {}

    public getClients = (): Observable<ClientInterface[]> => {
        return this.http.get<ClientInterface[]>('clients');
    }

    public createClient = (client:ClientInterface): Observable<ClientInterface> => {
        return this.http.post<ClientInterface>('clients', {
            name: client.name,
            last_name: client.last_name,
            type_nui: client.type_nui,
            nui: client.nui,
            type: client.type,
            dob: client.dob
        });
    }

    public updateClient = (client:ClientInterface): Observable<ClientInterface> => {
        return this.http.put<ClientInterface>(`clients/${client.uuid}`,{
            name: client.name,
            last_name: client.last_name,
            type_nui: client.type_nui,
            nui: client.nui,
            type: client.type,
            dob: client.dob
        });
    }

    public deleteClient = (uuid: string): Observable<any> => {
        return this.http.delete(`clients/${uuid}`);
    }

    public searchClient = (uuid: string, uuids: string[] = null): Observable<any> => {
        if (uuids == null){
            return this.http.post(`clients/${uuid}/search`, {});
        }
        else
        {
            return this.http.post(`clients/multi/search`, {
                uuids:uuids,
            });
        }

    }

    public getClient = (uuid:string): Observable<ClientInterface> => {
        return this.http.get<ClientInterface>(`clients/${uuid}`);
    }

}
