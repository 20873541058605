import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {Afiliado} from "../../interfaces/iess/iess";
import {DividerModule} from "primeng/divider";

@Component({
  selector: 'app-iess-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule,
        DividerModule
    ],
  templateUrl: './iess-display.component.html',
  styleUrl: './iess-display.component.scss'
})
export class IessDisplayComponent {
    @Input() afiliado: Afiliado;
}
