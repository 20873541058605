<div class="mt-0">
    <div class="card">
        <h5>Búsqueda de personas por nombre y/o identificación</h5>
        <p-divider />

        <p-tabView styleClass="tabview-custom">
            <p-tabPanel header="Personas">
                <div [formGroup]="form">
                    <ng-template pTemplate="header">
                        <i class="pi pi-user"></i>
                        <span class="ml-1">Personas</span>
                    </ng-template>
                    <div class="grid">
                        <div class="col-4">
                            <label>Apellidos: </label><br />
                            <input formControlName="last_name"
                                   pInputText id="lastname"
                                   type="text"
                                   placeholder="Parciales o completos"
                                   class="w-25rem my-2" /><br />
                            <small class="ng-dirty ng-invalid" *ngIf="submitted && form.get('last_name').hasError('required')">El campo de Apellidos es requerido.</small>
                        </div>
                        <div class="col-4">
                            <label>Nombres: </label><br />
                            <input formControlName="name"
                                   pInputText
                                   id="name"
                                   type="text"
                                   placeholder="Parciales o completos"
                                   class="w-25rem my-2" /><br />
                            <small class="ng-dirty ng-invalid" *ngIf="submitted && form.get('name').hasError('required')">El campo de Nombres es requerido.</small>
                        </div>
                        <div class="col-4">
                            <label htmlFor="id">Identificación</label><br />
                            <input pInputText
                                   formControlName="id"
                                   id="id"
                                   placeholder="Documento de identidad"
                                   type="text"
                                   class="w-25rem my-2" /><br />

                        </div>
                    </div>
                    <div class="flex flex-wrap gap-2">
                        <p-button label="Buscar"
                                  icon="pi pi-search"
                                  (onClick)="search()"
                                  [disabled]="!(searchCount?.busquedas_disponibles>0)">
                        </p-button>
                        <p-toast [life]="5500"></p-toast>
                    </div>
                </div>
            </p-tabPanel>

            <p-tabPanel header="Entidades">
                <div [formGroup]="formEntidad">
                    <ng-template pTemplate="header">
                        <i class="pi pi-building"></i>
                        <span class="ml-1">Entidades</span>
                    </ng-template>
                    <div class="grid">
                        <div class="col-4">
                            <label>Nombre o razón social: </label><br />
                            <input formControlName="name"
                                   pInputText
                                   id="name"
                                   type="text"
                                   placeholder="Nombre o razón social"
                                   class="w-25rem my-2" /><br />
                            <small class="ng-dirty ng-invalid" *ngIf="submitted && formEntidad.get('name').hasError('required')">El campo de Nombre o razón social es requerido.</small>
                        </div>
                        <div class="col-4">
                            <label>RUC: </label><br />
                            <input formControlName="ruc"
                                   pInputText
                                   id="ruc"
                                   type="text"
                                   placeholder="RUC"
                                   class="w-25rem my-2" /><br />

                        </div>
                    </div>
                    <div class="flex flex-wrap gap-2">
                        <p-button label="Buscar"
                                  icon="pi pi-search"
                                  (onClick)="searchEntity()"
                                  [disabled]="historicSearch?.length >= user?.search_count">
                        </p-button>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>

    </div>
</div>

<div class="card mt-5">
    <p-table [value]="historicSearch" [rowHover]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="link">Enlace Evidencia <p-sortIcon field="first-name"></p-sortIcon></th>
                <th pSortableColumn="first-name">Nombre(s) <p-sortIcon field="first-name"></p-sortIcon></th>
                <th pSortableColumn="last-name">Apellido(s) <p-sortIcon field="last-name"></p-sortIcon></th>
                <th pSortableColumn="nui">Identificación <p-sortIcon field="nui"></p-sortIcon></th>
                <th pSortableColumn="date">Fecha de Búsqueda <p-sortIcon field="search-date"></p-sortIcon></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-search>
            <tr>
                <td>
                    <button pButton pRipple label="Ver"
                            icon="pi pi-angle-double-right"
                            iconPos="right"
                            class="p-button-raised p-button-green"
                            [routerLink]="['/search', search.uuid]">
                    </button>
                </td>
                <td>{{search.query.name}}</td>
                <td>{{search.query.last_name}}</td>
                <td>{{search.query.id}}</td>
                <td>{{search.created_at | date: 'yyyy/MM/dd hh:mm a'}}</td>
            </tr>
        </ng-template>

    </p-table>
</div>
