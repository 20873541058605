import { Component, OnInit } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { NgClass, NgIf } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RippleModule } from "primeng/ripple";
import { SharedModule } from "primeng/api";
import { TableModule } from "primeng/table";
import { ToolbarModule } from "primeng/toolbar";
import { MessageService } from 'primeng/api';
import { Item } from "../../../interfaces/item";
import { ItemsService } from "../../../services/items.service";
import { ListsService } from "../../../services/lists.service";
import { ActivatedRoute } from "@angular/router";
import { List } from "../../../interfaces/list";
import { PaginatorModule } from "primeng/paginator";

@Component({
    selector: 'app-items-list',
    standalone: true,
    imports: [
        ButtonModule,
        DialogModule,
        InputTextModule,
        NgIf,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToolbarModule,
        PaginatorModule,
        NgClass
    ],
    templateUrl: './items-list.component.html',
    styleUrl: './items-list.component.scss',
    providers: [MessageService]
})
export class ItemsListComponent implements OnInit{

    items: Item[];
    item: Item;
    selectedItems: Item[] = [];
    uuid: string;
    list: List;

    submitted : boolean = false;
    itemDialog : boolean = false;
    deleteItemsDialog : boolean = false;
    cols: any[] = [];

    isCreating: boolean = false;

    constructor(private itemService: ItemsService,
                private messageService: MessageService,
                private listService: ListsService,
                private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
            this.loadList();
            this.loadItems();
        });
    }

    loadList(){
        this.listService.get(this.uuid).subscribe(list => {
            this.list = list;
        });
    }

    loadItems(){
        this.itemService.getItems(this.uuid).subscribe(data => {
            this.items = data;
        });
    }

    openNew(){
        this.item = {
            name: "",
            last_name: "",
            nui: "",
            details: ""
        } as Item;
        this.isCreating = false;
        this.submitted = false;
        this.itemDialog = true;
    }

    createItem(){
        this.isCreating = true;
        this.itemService.createItem(this.item, this.uuid).subscribe(data => {
            this.hideDialog();
            this.loadItems();
            this.isCreating = false;
        });
    }

    hideDialog() {
        this.itemDialog = false;
        this.submitted = false;
    }

    deleteSelectedItems() {
        this.deleteItemsDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteItemsDialog = false;
        this.items = this.items.filter(val => !this.selectedItems.includes(val));
        this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Items Eliminados', life: 3000 });

        this.selectedItems.forEach((val) => {
            this.itemService.deleteItem(this.uuid, val.uuid.toString()).subscribe(()=> {
                this.loadItems();
            });
        });
        this.selectedItems = [];
    }


}
