import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {NNUURecord} from "../../interfaces/nnuu/nnuu";

@Component({
  selector: 'app-nnuu-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
  templateUrl: './nnuu-display.component.html',
  styleUrl: './nnuu-display.component.scss'
})
export class NnuuDisplayComponent {

    @Input() records: NNUURecord[] = [];

}
