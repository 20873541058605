<p-toast [life]="5500"></p-toast>
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/logos/APLASoft.png" alt="APLASoft" class="mb-3 w-14rem flex-shrink-0">
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="text-center mb-5">
                    <span class="text-600 font-medium">Ingresa su e-mail para reestablecer su contraseña</span>
                </div>

                    <form [formGroup]="form">
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Correo electrónico</label>
                        <input [readOnly]="isLoading" [disabled]="isLoading" formControlName="email" id="email" type="text" placeholder="Correo electrónico" pInputText class="w-full md:w-30rem mb-5" style="padding:1rem">

                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <button [loading]="isLoading" pButton pRipple label="Enviar email" class="w-full p-3 text-xl" (click)="resetEmail()"></button>
                        </div>
                    </form>

            </div>
        </div>
    </div>
</div>
