import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { List } from "../interfaces/list";

@Injectable({
  providedIn: 'root'
})
export class ListsService {

    constructor(private http: HttpClient) {}

    public getLists = (): Observable<List[]> => {
        return this.http.get<List[]>('black_list');
    }

    public deleteList = (id: string): Observable<any> => {
        return this.http.delete(`black_list/${id}`);
    }

    public updateList = (list:List): Observable<List> => {
        return this.http.put<List>(`black_list/${list.uuid}`, {
            name: list.name
        });
    }

    public createList = (list:List): Observable<List> => {
        return this.http.post<List>('black_list', {
            name: list.name
        });
    }

    public get =(uuid: string): Observable<List> => {
        return this.http.get<List>(`black_list/${uuid}`);
    }

}
