import {Component, OnInit} from '@angular/core';
import {AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {MultiSelectModule} from "primeng/multiselect";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ToastModule} from "primeng/toast";
import {ToolbarModule} from "primeng/toolbar";
import {NotificationService} from "../../services/notification-service.service";
import {SupplierInterface} from "../../interfaces/supplier";
import {SuppliersService} from "../../services/suppliers.service";
import {RouterLink} from "@angular/router";
import {UpdateUserCountComponent} from "../users/components/update-user-count/update-user-count.component";
import * as moment from "moment/moment";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";

interface Type {
    name: string;
    value: string;
}

@Component({
    selector: 'app-suppliers',
    standalone: true,
    imports: [
        AutoCompleteModule,
        ButtonModule,
        CalendarModule,
        DialogModule,
        DropdownModule,
        InputTextModule,
        NgClass,
        NgIf,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToastModule,
        ToolbarModule,
        RouterLink,
        UpdateUserCountComponent,
        FormsModule,
        MultiSelectModule
    ],
    templateUrl: './suppliers.component.html',
    styleUrl: './suppliers.component.scss',
    providers: [NotificationService, MessageService]
})
export class SuppliersComponent implements OnInit{


    suppliers: SupplierInterface[];
    selectedSuppliers: SupplierInterface[] = [];

    nuiTypes: Type[] = [];
    supplierTypes: Type[] = [];
    cols: any[] = [];
    supplierForm: FormGroup;

    submitted : boolean = false;
    supplierDialog : boolean = false;
    deleteSuppliersDialog : boolean = false;

    constructor(private supplierService: SuppliersService,
                private messageService: MessageService,
                private notificationService: NotificationService) { }

    ngOnInit() {
        this.createForm();
        this.loadSuppliers();
        this.nuiTypes = [
            { name: 'Cédula', value: 'cedula' },
            { name: 'RUC', value: 'ruc' }
        ];
        this.supplierTypes = [
            { name: 'Individuo', value: 'person' },
            { name: 'Compañía', value: 'company' }
        ];
    }

    loadSuppliers(){
        this.supplierService.getSuppliers().subscribe(suppliers => {
            this.suppliers = suppliers;
        });
    }

    openNew(){
        this.createForm();
        this.submitted = false;
        this.supplierDialog = true;
    }

    editSelectedSuppliers(supplier:SupplierInterface) {
        this.supplierForm.patchValue({
            name: supplier.name,
            last_name: supplier.last_name,
            type_nui: supplier.type_nui,
            nui: supplier.nui,
            type: supplier.type,
            dob: moment(supplier.dob, 'YYYY-MM-DD').toDate(),
            uuid: supplier.uuid
        });
        this.submitted = false;
        this.supplierDialog = true;
    }

    createForm(){
        this.supplierForm = new FormGroup({
            'name': new FormControl(""),
            'last_name': new FormControl(""),
            'type_nui': new FormControl(""),
            'nui': new FormControl(""),
            'type': new FormControl(""),
            'dob': new FormControl(""),
            'uuid': new FormControl("")
        });
    }

    closeModal(){
        this.hideDialog();
        this.loadSuppliers();
    }

    hideDialog() {
        this.supplierDialog = false;
        this.submitted = false;
    }

    createSupplier(){
        if (this.supplierForm.valid){
            this.supplierService.createSupplier(this.supplierForm.value).pipe(
                catchError(error => {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Límite de Búsquedas',
                        detail: 'Su cupo contratado ha sido alcanzado.'
                    })
                    return of(null);
                })
            ).subscribe(data => {
                this.hideDialog();
                this.loadSuppliers();
            });
        }
    }

    updateSupplier() {
        this.submitted = true;
        if (this.supplierForm.valid) {
            this.supplierService.updateSupplier(this.supplierForm.value).subscribe(data =>{
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Proveedor Actualizado', life: 3000 });
                this.hideDialog();
                this.loadSuppliers();
            });
        }
    }

    performAction(){
        if (this.supplierForm.get('uuid').value.length == 0)
        {
            this.createSupplier();
        }
        else {
            this.updateSupplier();
        }
    }

    deleteSelectedSuppliers() {
        this.deleteSuppliersDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteSuppliersDialog = false;
        this.selectedSuppliers.forEach((val) => {
            this.supplierService.deleteSupplier(val.uuid.toString()).subscribe(()=> {
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Proveedores Eliminados', life: 3000 });
                this.loadSuppliers();
            });
        });
        this.selectedSuppliers = [];
    }

    searchSupplier(supplier: SupplierInterface, showNotification: boolean = true){
        supplier.loading = true;
        if (showNotification) {
            this.supplierService.searchSupplier(supplier.uuid).subscribe(()=>{
                this.notificationService.showWarnSearched('El registro se encuentra en búsqueda');
                supplier.loading = false;
            });
        }
    }

    searchSelectedSuppliers(){
        if (this.selectedSuppliers.length === 0) {
            this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'No se han seleccionado proveedores.', life: 5000 });
            return;
        }

        const uuids: string[] = this.selectedSuppliers.map(supplier => supplier.uuid);
        this.supplierService.searchSupplier(null, uuids).subscribe( () => {
            this.notificationService.showWarnSearched('Los registros se encuentran en búsqueda');
        });
    }


}
