import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {PlanInterface} from "../../interfaces/plan";
import {PlansService} from "../../services/plans.service";
import {RadioButtonModule} from "primeng/radiobutton";
import {DropdownModule} from "primeng/dropdown";
import {DialogService} from "primeng/dynamicdialog";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-plans',
    standalone: true,
    imports: [
        ButtonModule,
        DialogModule,
        FormsModule,
        InputTextModule,
        NgIf,
        RippleModule,
        SharedModule,
        TableModule,
        ToolbarModule,
        RadioButtonModule,
        DropdownModule,
        ReactiveFormsModule
    ],
    templateUrl: './plans.component.html',
    styleUrl: './plans.component.scss',
    providers: [MessageService, DialogService]
})
export class PlansComponent implements OnInit{

    plans: PlanInterface[];
    selectedPlans: PlanInterface[] = [];
    uuid: string;
    cols: any[] = [];
    form: FormGroup;
    companyId: string;

    deletePlansDialog: boolean = false;
    submitted: boolean = false;

    planDialog: boolean = false;

    options = [
        { label: 'Clientes', value: 'Clientes' },
        { label: 'Búsquedas', value: 'Búsquedas' }
    ];


    constructor(private planService: PlansService, private messageService: MessageService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.companyId = params['cuuid'];
            this.loadPlans();
        });
        this.form = new FormGroup({
            'type': new FormControl('Clientes', Validators.required),
            'quantity': new FormControl(0, Validators.required),
            'start_date': new FormControl('', Validators.required),
            'end_date': new FormControl('', Validators.required)
        });
    }

    hideDialog() {
        this.planDialog = false;
    }

    openNew(){
        this.form.reset({
            'type': "Clientes",
            'quantity': 0,
            'start_date': moment().format('YYYY-MM-DD'),
            'end_date': moment().add(1,'year').format('YYYY-MM-DD'),
        });
        this.submitted = false;
        this.planDialog = true;
    }

    createPlan() {
        if (this.form.invalid){
            return;
        }

        this.planService.createPlan(this.form.value, this.companyId).subscribe(data => {
            this.hideDialog();
            this.loadPlans();
        });
    }

    loadPlans(){
        this.planService.getPlans(this.companyId).subscribe(data => {
            this.plans = data;
        });
    }

    deleteSelectedPlans(){
        this.deletePlansDialog = true;
    }

    confirmDeleteSelected(){
        this.deletePlansDialog = false;
        this.plans = this.plans.filter(val => !this.selectedPlans.includes(val));
        this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Planes Eliminados', life: 3000 });

        this.selectedPlans.forEach((plan) => {
           this.planService.deletePlan(plan.uuid.toString()).subscribe(()=> {
               this.loadPlans();
           });
        });
    }

}
