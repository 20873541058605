import {Component, inject, OnInit} from '@angular/core';
import {SearchService} from "../../../services/search/search.service";
import {ClientsService} from "../../../services/clients.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ClientInterface} from "../../../interfaces/client";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ReactiveFormsModule} from "@angular/forms";
import {DialogModule} from "primeng/dialog";
import {DatePipe, NgIf} from "@angular/common";
import {ReportLinkDirective} from "../../../directives/report-link.directive";
import {Observable} from "rxjs";
import {SearchCountResponse} from "../../../interfaces/search-count";
import {Store} from "@ngxs/store";
import {SearchCountState} from "../../../store/user/search-count/search-count.state";
import {SuppliersService} from "../../../services/suppliers.service";
import {EmployeesService} from "../../../services/employees.service";

@Component({
    selector: 'app-search-list',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        RippleModule,
        SharedModule,
        TableModule,
        ReactiveFormsModule,
        DialogModule,
        RouterLink,
        NgIf,
        ReportLinkDirective,
        DatePipe
    ],
    templateUrl: './search-list.component.html',
    styleUrl: './search-list.component.scss'
})
export class SearchListComponent implements OnInit{

    client: ClientInterface;
    clients: ClientInterface[];
    selectedClients: ClientInterface[] = [];
    cols: any[] = [];

    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    constructor(private searchService:SearchService,
                private clientService:ClientsService,
                private supplierService: SuppliersService,
                private employeeService: EmployeesService,
                private route:ActivatedRoute) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const resource = params['resource'];

            switch (resource) {
                case SearchResource.clients:
                    this.clientService.getClient(params['uuid']).subscribe(client => {
                        this.client = client;
                    });
                break;
                case SearchResource.suppliers:
                    this.supplierService.getSupplier(params['uuid']).subscribe(client => {
                        this.client = client;
                    });
                break;
                case SearchResource.employees:
                    this.employeeService.getEmployee(params['uuid']).subscribe(client => {
                        this.client = client;
                    });
                break;
            }


        });
    }

}

export enum SearchResource {

    clients = 'clients',
    suppliers = 'suppliers',
    employees = 'employees'

}
