<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Lista" icon="pi pi-plus" class="p-button mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Lista" icon="pi pi-trash" class="p-button mr-2 mt-2" (click)="deleteSelectedLists()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="lists" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedLists" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Listas</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="name">Acciones <p-sortIcon field="name"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-list>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="list"></p-tableCheckbox>
                        </td>
                        <td><span class="p-column-title">Nombre</span>
                            {{list.name}}
                        </td>
                        <td>
                            <p-button label="Agregar Registros" [routerLink]="['/lists', list.uuid, 'items']" icon="pi pi-pencil" styleClass="p-button-warning"></p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>

        <p-dialog [(visible)]="listDialog" [style]="{width: '450px', height: '280px'}" header="Nueva Lista" [modal]="true" class="p-fluid">

            <ng-template pTemplate="content">
                <div class="field">
                    <label for="name">Nombre</label>
                    <input type="text" pInputText id="name" [(ngModel)]="list!.name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !list!.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !list!.name">Nombre es requerido.</small>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="createList()"></button>
            </ng-template>

        </p-dialog>

        <p-dialog [(visible)]="deleteListsDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar la lista?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteListsDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>
