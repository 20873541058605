<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="records" rows="10" paginator="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Dirección
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Tipo
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Programa
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Lista
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Score
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    <a [href]="record.link" target="_blank" [innerHTML]="record.name"></a>
                </td>
                <td>
                    {{record.address}}
                </td>
                <td>
                    {{record.type}}
                </td>
                <td>
                    {{record.programs}}
                </td>
                <td>
                    {{record.list}}
                </td>
                <td>
                    {{record.score}}
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
