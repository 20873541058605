<div class="mt-0">
    <div class="card">
        <h5>Búsqueda de personas por nombre y/o identificación</h5>
        <p-divider />

        <p-tabView styleClass="tabview-custom">

            <div class="grid" [formGroup]="form">
                <div class="col-6">
                    <label>Nombre del lote: </label><br />
                    <input formControlName="name"
                           [ngClass]="{'ng-invalid ng-dirty': (!form.get('name').valid) && (form.get('name').dirty)}"
                           pInputText id="name" type="text" placeholder="Lote" class="w-full my-2" />
                </div>
                <div class="col-4">
                    <a href="https://aplasoft-api.lideresapla.com/Ejemplo_de_búsqueda.xlsx" alt="Descargar Archivo de Ejemplo"
                       title="Descargar Archivo de Ejemplo" target="_blank">
                        <p-tag icon="pi pi-info-circle" severity="info" value="Descargar Archivo de Ejemplo"></p-tag>
                    </a><br>
                    <label>Archivo: </label><br />
                    <input type="file" #file accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           (change)="selectFile($event)">
                </div>
            </div>

            <div class="flex flex-wrap gap-2">
                <p-button label="Buscar lote" icon="pi pi-search"
                          (click)="searchBatch()"
                          [disabled]="!(searchCount?.busquedas_disponibles>0) || isButtonDisabled"
                          [loading]="loading">
                </p-button>
                <p-toast [life]="5500"></p-toast>
            </div>

        </p-tabView>

    </div>
</div>

<div class="card mt-5">
    <h5>Lotes de búsqueda</h5>
    <p-divider />

    <p-table [value]="searchList" [rowHover]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="link">Acciones <p-sortIcon field="first-name"></p-sortIcon></th>
                <th pSortableColumn="link">Nombre de lote <p-sortIcon field="first-name"></p-sortIcon></th>
                <th pSortableColumn="date">Fecha de Búsqueda <p-sortIcon field="search-date"></p-sortIcon></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-lote>
            <tr>
                <td>
                    <a [routerLink]="['/batch_search', lote.uuid]">
                        <button pButton pRipple label="Ver" icon="pi pi-angle-double-right"
                                iconPos="right" class="p-button-raised p-button-success">
                        </button>
                    </a>
                </td>
                <td>{{lote.name}}</td>
                <td>{{lote.created_at | date: 'yyyy/MM/dd hh:mm a'}}</td>
            </tr>
        </ng-template>

    </p-table>
</div>
