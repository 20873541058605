import {Directive, HostListener, Input} from '@angular/core';
import {UrlService} from "../services/url/url.service";

@Directive({
  selector: '[laLink]',
  standalone: true
})
export class LaLinkDirective {

    @Input() laLink: string;
    @Input() uuid: string;
    constructor(private urlService: UrlService) {}

    @HostListener('click', ['$event'])
    onClick($event: Event) {
        $event.preventDefault();
        this.urlService.getInstanceLink(this.uuid, this.laLink).subscribe(data => {
            window.open(data.url, '_blank')
        });
    }
}
