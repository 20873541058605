import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {User} from "../../../../interfaces/user";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-update-user-count',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        ReactiveFormsModule,
        FormsModule,
        NgIf
    ],
    templateUrl: './update-user-count.component.html',
    styleUrl: './update-user-count.component.scss'
})
export class UpdateUserCountComponent {

    @Input() userc:User;
    @Output() changeSearchCount = new EventEmitter<User>();
    editing: boolean = false;

}
