import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {Cne} from "../../interfaces/cne/cne";

@Component({
    selector: 'app-cne-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
  templateUrl: './cne-display.component.html',
  styleUrl: './cne-display.component.scss'
})
export class CneDisplayComponent {

    @Input() records: Cne[] = [];

}
