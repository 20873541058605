import { Injectable } from '@angular/core';
import {State, Selector, Action, StateContext} from '@ngxs/store';
import {LoadSearchCountAction} from "./search-count.actions";
import {AuthService} from "../../../services/auth.service";
import {SearchCountResponse} from "../../../interfaces/search-count";


@State<SearchCountResponse>({
    name: 'searchCount',
    defaults: {
        'busquedas_realizadas': "",
        'busquedas_contratadas': "",
        'busquedas_disponibles': "",
        'company_name': "",
        'plan_type': "",
        'plan_start_date': "",
        'plan_end_date': "",
    }
})
@Injectable()
export class SearchCountState {

    constructor(private authService:AuthService) {}

    @Selector()
    static getState(state: SearchCountResponse) {
        return state;
    }

    @Action(LoadSearchCountAction)
    loadSearchCount({ setState }: StateContext<SearchCountResponse>) {
        this.authService.getSearchCount().subscribe(data=> {
           setState(data);
        });
    }

}
