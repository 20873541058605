<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="records" rows="10" paginator="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Dignidad
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Organzación Política
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Lista
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Provincia
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Sexo
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Candidato Estado
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Principal
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    {{record.candidato}}
                </td>
                <td>
                    {{record.dignidad}}
                </td>
                <td>
                    <p [title]="record.organzacion_politica">{{record.siglas}}</p>
                </td>
                <td>
                    {{record.lista}}
                </td>
                <td>
                    {{record.provincia}}
                </td>
                <td>
                    {{record.sexo}}
                </td>
                <td>
                    {{record.candidato_estado}}
                </td>
                <td>
                    {{record.principal}}
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
