import {Component, inject, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {SharedModule} from "primeng/api";
import {ToolbarModule} from "primeng/toolbar";
import {User} from "../../interfaces/user";
import {DialogModule} from "primeng/dialog";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {TableModule} from "primeng/table";
import {UsersService} from "../../services/users.service";
import {MessageService} from 'primeng/api';
import {UpdateUserCountComponent} from "./components/update-user-count/update-user-count.component";
import { ActivatedRoute } from '@angular/router';
import {MultiSelectModule} from "primeng/multiselect";
import {RolesService} from "../../services/roles.service";
import {RoleInterface} from "../../interfaces/role";
import {ListboxModule} from "primeng/listbox";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {Observable} from "rxjs";
import {Store} from "@ngxs/store";
import {UserState} from "../../store/user/store/user.state";

@Component({
    selector: 'app-users',
    standalone: true,
    imports: [
        ButtonModule,
        RippleModule,
        SharedModule,
        ToolbarModule,
        DialogModule,
        FormsModule,
        InputTextModule,
        NgIf,
        TableModule,
        UpdateUserCountComponent,
        MultiSelectModule,
        ListboxModule,
        AutoCompleteModule,
        ReactiveFormsModule
    ],
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss',
    providers: [MessageService]
})
export class UsersComponent implements OnInit{

    users: User[];
    selectedUsers: User[] = [];
    demoUsers: User[] = [];
    filteredUsers: User[] = [];
    cols: any[] = [];
    selectedUser: User;
    user$: Observable<User> = inject(Store).select(UserState.currenUser);
    currentUser: User;
    submitted : boolean = false;
    userDialog : boolean = false;
    deleteUsersDialog : boolean = false;
    importDialog: boolean = false;
    guuid: string;
    cuuid: string;
    roles: RoleInterface[];
    form: FormGroup;

    constructor(private userService: UsersService,
                private messageService: MessageService,
                private route: ActivatedRoute,
                private rolesService: RolesService) {}

    ngOnInit() {
        this.form = new FormGroup({
            uuid: new FormControl(null),
            name: new FormControl('', Validators.required),
            last_name: new FormControl('', Validators.required),
            roles: new FormControl([]),
            email: new FormControl('', Validators.required),
            nui: new FormControl(''),
            search_count: new FormControl(0),
        });
        this.route.paramMap.subscribe(params => {
            this.guuid = params.get('guuid');
            this.cuuid = params.get('cuuid');
            this.loadUsers();
        });
        this.user$.subscribe(user => {
            this.currentUser = user
        });
    }

    loadUsers(){
        const filters:any = {};
        if (this.cuuid)
        {
            filters.company_id = this.cuuid;
        }

        this.userService.getUsers(filters).subscribe(data => {
            this.users = data.users;
        });
        this.rolesService.getRoles().subscribe(roles => {
            this.roles = roles
        });
    }

    openNew(){
        this.form.reset({
            name: "",
            last_name: "",
            roles: [],
            email: "",
            nui: "",
            search_count: 0
        });
        this.submitted = false;
        this.userDialog = true;
    }

    closeModal(){
        this.hideDialog();
        this.importDialog = false;
        this.loadUsers();
    }

    editSelectedUser(user: User){
        this.userService.getUser(user.uuid).subscribe(user=> {
            this.form.reset({
                uuid: user.uuid,
                name: user.name,
                last_name: user.last_name,
                roles: user.roles_array,
                email: user.email,
                nui: user.nui,
                search_count: user.search_count
            });
            this.userDialog = true;
        });
    }

    createUser(){
        if (this.form.invalid){
            return;
        }
        this.userService.createUser(this.form.value, this.cuuid).subscribe(data => {
            this.hideDialog();
            this.loadUsers();
        });
    }

    updateUser() {
        if (this.form.invalid){
            return;
        }
        this.userService.updateUser(this.form.value, this.cuuid).subscribe(data => {
            this.hideDialog();
            this.loadUsers();
        });
    }

    performAction(){
        this.form.markAllAsTouched();
        this.form.markAsTouched();

        if (this.form.get('uuid').value !== null) {
            this.updateUser();
        } else {
            this.createUser();
        }
    }

    showImportUser(){
        this.importDialog = true;
        this.userService.getUsers({demo_users:true}).subscribe(users => {
            this.demoUsers = users.users;
        });
    }

    filterUser(event: AutoCompleteCompleteEvent) {
        const query = event.query;
        this.filteredUsers = this.demoUsers.filter(user => {
            return user.name.includes(query);
        });

    }

    importUser(){
        this.userService.importUser(this.cuuid, this.selectedUser.uuid).subscribe(response => {
            this.loadUsers();
            this.importDialog = false;
            this.selectedUser = undefined;
        });
    }

    changeSearchCount(user: User){
        this.userService.updateUser(user, this.cuuid).subscribe(data => {
            this.loadUsers();
        });
    }

    hideDialog() {
        this.userDialog = false;
        this.submitted = false;
    }

    deleteSelectedUsers() {
        this.deleteUsersDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteUsersDialog = false;
        this.users = this.users.filter(val => !this.selectedUsers.includes(val));
        this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Usuarios Eliminados', life: 3000 });

        this.selectedUsers.forEach((val) => {
            this.userService.deleteUser(val.id.toString()).subscribe(()=> {
                this.loadUsers();
            });
        });
        this.selectedUsers = [];
    }

    deleteUser(){
        this.userService.getUsers().subscribe(data => {
            this.users = data.users;
        });
    }

}
