<div class="grid">
    <div class="col-4 p-2">
        <h5>Información del Afiliado</h5>
        <p-divider />
        <b>Afiliado:</b> {{ afiliado.name }}<br />
        <b>Identificacón:</b> {{ afiliado.nui }}<br />
        <b>Estado</b> {{ afiliado.status }}<br />
        <b>Fecha de último movimiento:</b> {{ afiliado.date }}
    </div>

    <div class="col-8 p-2">
        <div class="p-card-title font-bold"><h5>Información del Empleador</h5></div>
        <p-divider />

        <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="afiliado.companies">

            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            Empresa(s)
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-empresa>
                <tr>
                    <td>
                        {{ empresa }}
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>
