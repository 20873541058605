import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ShellCompanies} from "../../interfaces/shell-companies/shell-companies";

@Component({
    selector: 'app-shell-companies-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
    templateUrl: './shell-companies-display.component.html',
    styleUrl: './shell-companies-display.component.scss'
})
export class ShellCompaniesDisplayComponent {
    @Input() records: ShellCompanies[] = [];
}
