import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {CompanyInterface} from "../../interfaces/company";
import {CompaniesService} from "../../services/companies.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {FileUploadModule} from 'primeng/fileupload';

@Component({
    selector: 'app-companies',
    standalone: true,
    imports: [
        ButtonModule,
        DialogModule,
        InputTextModule,
        NgIf,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToolbarModule,
        FormsModule,
        FileUploadModule,
        NgClass,
        RouterLink
    ],
    templateUrl: './companies.component.html',
    styleUrl: './companies.component.scss',
    providers: [MessageService]
})
export class CompaniesComponent implements OnInit{

    companies: CompanyInterface[];
    uuid: string;
    selectedCompanies: CompanyInterface[] = [];

    submitted: boolean = false;
    companyDialog: boolean = false;
    deleteCompaniesDialog: boolean = false;
    cols: any[] = [];
    isSaving: boolean = false;
    file: File = undefined;
    form: FormGroup;

    constructor(private companyService: CompaniesService,
                private messageService: MessageService,
                private route: ActivatedRoute) {}

    ngOnInit() {
        this.form = new FormGroup({
            uuid: new FormControl(null),
            name: new FormControl('', Validators.required),
            nui: new FormControl('', Validators.required),
            logo: new FormControl(null),
            address: new FormControl(''),
            phone: new FormControl(''),
            activity: new FormControl(''),
            legal_representative_id: new FormControl(''),
            legal_representative: new FormControl(''),
            employee_numbers: new FormControl(0, Validators.pattern("^[0-9]*$")),
            registration_number: new FormControl('')
        });
        this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
            this.loadCompanies();
        });
    }

    loadCompanies() {
        this.companyService.getCompanies(this.uuid).subscribe(data => {
            this.companies = data.companies;
        });
    }

    openNew(){
        this.form.reset({
            uuid: null,
            name: "",
            nui: "",
            logo: null,
            address: "",
            phone: "",
            activity: "",
            legal_representative_id: "",
            legal_representative: "",
            employee_numbers: 0,
            registration_number: ""
        });
        this.submitted = false;
        this.companyDialog = true;
    }

    editSelectedCompanies(company:CompanyInterface) {
        this.form.reset({
            uuid: company.uuid,
            name: company.name,
            nui: company.nui,
            logo: null,
            address: company.address,
            phone: company.phone,
            activity: company.activity,
            legal_representative_id: company.legal_representative_id,
            legal_representative: company.legal_representative,
            employee_numbers: company.employee_numbers,
            registration_number: company.registration_number
        });

        this.submitted = false;
        this.companyDialog = true;
    }

    performAction(){

        this.form.markAllAsTouched();
        this.form.markAsTouched();

        if (this.form.get('uuid').value !== null) {
            this.updateCompany();
        }
        else {
            this.createCompany();
        }
    }

    selectFile(event: Event) {
        // @ts-ignore
        this.file = event.target.files[0];
    }

    createCompany(){

        if (this.form.invalid){
            return;
        }

        if (this.isSaving){
            return;
        }
        this.isSaving = true;

        this.companyService.createCompanies(this.form.value, this.uuid, this.file).subscribe(data => {
            this.hideDialog();
            this.loadCompanies();
        });
    }

    updateCompany(){

        if (this.form.invalid){
            return;
        }

        if (this.isSaving){
            return;
        }
        this.isSaving = true;

        this.companyService.updateCompanies(this.form.value, this.uuid, this.file).subscribe(data => {
            this.companyDialog = false;
            this.loadCompanies();
        });
    }

    hideDialog() {
        this.companyDialog = false;
        this.submitted = false;
    }

    deleteSelectedCompanies(){
        this.deleteCompaniesDialog = true;
    }

    confirmDeleteSelected(){
        this.deleteCompaniesDialog = false;
        this.companies = this.companies.filter(val => !this.selectedCompanies.includes(val));
        this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Compañías Eliminadas', life: 3000 });

        this.selectedCompanies.forEach((val) => {
            this.companyService.deleteCompanies(val.uuid.toString()).subscribe(()=> {
                this.loadCompanies();
            });
        });
        this.selectedCompanies = [];
    }

}
