import {Directive, HostListener, Input} from '@angular/core';
import {UrlService} from "../services/url/url.service";

@Directive({
  selector: '[reportLink]',
  standalone: true
})
export class ReportLinkDirective {

    @Input() reportLink: string;
    @Input() uuid: string;
    constructor(private urlService: UrlService) {}

    @HostListener('click', ['$event'])
    onClick($event: Event) {
        $event.preventDefault();
        this.urlService.getReportLink(this.uuid, this.reportLink).subscribe(data => {
            window.open(data.url, '_blank')
        });
    }

}
