import { Injectable } from '@angular/core';
import {HttpHandler, HttpRequest} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService {

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const options = {
            url: `${environment.api}${req.url}`,
            setHeaders: {}
        };


        const token = sessionStorage.getItem('usrToken');

        if (token) {
            options.setHeaders = {
                Authorization: `Bearer ${token}`,
            }
        }
        const apiReq = req.clone(options);

        return next.handle(apiReq);
    }
}
