<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Item" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Item" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedItems()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="items" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedItems" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Items de la Lista - {{ list?.name }}</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">Nombres <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="last_name">Apellidos <p-sortIcon field="last_name"></p-sortIcon></th>
                        <th pSortableColumn="nui">Identificación <p-sortIcon field="nui"></p-sortIcon></th>
                        <th pSortableColumn="details">Detalle <p-sortIcon field="details"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="item"></p-tableCheckbox>
                        </td>
                        <td><span class="p-column-title">Nombres</span>
                            {{ item.name }}
                        </td>
                        <td><span class="p-column-title">Apellidos</span>
                            {{ item.last_name }}
                        </td>
                        <td><span class="p-column-title">Apellidos</span>
                            {{ item.nui }}
                        </td>
                        <td><span class="p-column-title">Detalle</span>
                            {{ item.details }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>

        <p-dialog [(visible)]="itemDialog" [style]="{width: '450px', height: '600px'}" header="Nuevo Registro" [modal]="true" class="p-fluid">

            <ng-template pTemplate="content">
                <div class="field">
                    <label>Nombres</label>
                    <input type="text" pInputText [(ngModel)]="item!.name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !item!.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !item!.name">Nombres es requerido.</small>
                </div>
                <div class="field">
                    <label>Apellidos</label>
                    <input type="text" pInputText [(ngModel)]="item!.last_name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !item!.last_name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !item!.last_name">Apellidos es requerido.</small>
                </div>
                <div class="field">
                    <label>Identificación</label>
                    <input type="text" pInputText [(ngModel)]="item!.nui" autofocus />
                </div>
                <div class="field">
                    <label>Detalle</label>
                    <textarea type="text" pInputText [(ngModel)]="item!.details" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !item!.details}" rows="7"></textarea>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !item!.details">Detalle es requerido.</small>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" ></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="createItem()" [disabled]="isCreating"></button>
            </ng-template>

        </p-dialog>

        <p-dialog [(visible)]="deleteItemsDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el registro?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteItemsDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>
