import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ApiInterceptorService} from "./services/api-interceptor.service";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import { NgxsModule } from '@ngxs/store';
import {UserState} from "./store/user/store/user.state";
import {NotificationService} from "./services/notification-service.service";
import {SearchCountState} from "./store/user/search-count/search-count.state";

@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        ReactiveFormsModule,
        NgxsModule.forRoot(
            [
                UserState,
                SearchCountState
            ],
            { developmentMode: /** !environment.production */ false }
        )
    ],
    exports: [RouterModule, ReactiveFormsModule],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, NotificationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
