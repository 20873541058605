import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { Observable } from "rxjs";
import { PlanInterface } from "../interfaces/plan";

@Injectable({
    providedIn: 'root',
})
export class PlansService {

    constructor(private http: HttpClient) {}

    public getPlans = (companyId:string): Observable<PlanInterface[]> => {
        const parameters = new HttpParams().set('company_id', companyId);

        return this.http.get<PlanInterface[]>('plans', {params:parameters});
    }

    public createPlan = (plan:PlanInterface, cuuid:string): Observable<PlanInterface> => {
        return this.http.post<PlanInterface>('plans', {
            type: plan.type,
            quantity:  plan.quantity,
            start_date:  plan.start_date,
            end_date:  plan.end_date,
            cuuid: cuuid
        });
    }

    public deletePlan = (uuid: string): Observable<any> => {
        return this.http.delete(`plans/${uuid}`);
    }

    public updatePlan = (plan:PlanInterface, uuid:string): Observable<PlanInterface> => {
        return this.http.put<PlanInterface>(`plans/${uuid}`, {
            type: plan.type,
            quantity:  plan.quantity,
            start_date:  plan.start_date,
            end_date:  plan.end_date
        });
    }



}
