<p-toast [life]="5500"></p-toast>
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/logos/APLASoft.png" alt="APLALo Soft" class="mb-3 w-14rem flex-shrink-0">
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="text-center mb-5">
                    <div class="text-700 font-medium w-30rem mb-5 flex align-items-center justify-content-between">Software de Administración de Riesgos en Prevención de Lavado de Activos y Financiamiento del Terrorismo</div>
                    <span class="text-600 font-medium">Ingresa para continuar</span>
                </div>

                <div [formGroup]="form">
                    <form>
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Correo electrónico</label>
                        <input formControlName="email" id="email" type="text" placeholder="Correo electrónico" pInputText class="w-full md:w-30rem mb-5" style="padding:1rem">

                        <label class="block text-900 font-medium text-xl mb-2">Contraseña</label>
                        <p-password formControlName="password" placeholder="Contraseña" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>

                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <div class="flex align-items-center">
                                <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                                <label for="rememberme1">Recordar</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" (click)="resetPassword()" style="color: var(--primary-color)">¿Olvidó la Contraseña?</a>
                        </div>
                        <button pButton pRipple label="Iniciar sesión" [loading]="isLoading" class="w-full p-3 text-xl btlogin" (click)="login()"></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

