
<div class="">

    <div class="card" *ngFor="let noticia of records">
        <div>
            <h5>{{ noticia.title }}</h5>
            <p-divider />
            <b>Lugar:</b> {{ noticia.lugar }}<br />
            <b>Fecha:</b> {{ noticia.fecha }}<br />
            <b>Delito: </b> <strong>{{ noticia.delito }}</strong><br />
            <b>Unidad</b> {{ noticia.unidad }}<br />
            <b>Fiscalia</b> {{ noticia.fiscalia }}<br />

        </div>

        <div>
            <br>
            <br>
            <div class="p-card-title font-bold"><h5>Sujetos</h5></div>
            <p-divider />

            <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="noticia.subjects">

                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                Cedula
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                Nombre
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                Tipo
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-sujeto>
                    <tr>
                        <td>
                            {{ sujeto.cedula }}
                        </td>
                        <td>
                            {{ sujeto.name }}
                        </td>
                        <td>
                            {{ sujeto.estado }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>

</div>
