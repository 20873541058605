import {Component, Input} from '@angular/core';
import {Pena} from "../../interfaces/consejo-judicatura/penas";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";


@Component({
  selector: 'app-penas-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
  templateUrl: './penas-display.component.html',
  styleUrl: './penas-display.component.scss'
})
export class PenasDisplayComponent {

    @Input() penas: Pena[] = [];

}
