import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Causa} from "../../interfaces/consejo-judicatura/causas";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {DatePipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-causas-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule,
        MultiSelectModule,
        FormsModule,
        NgIf,
        DatePipe
    ],
  templateUrl: './causas-display.component.html',
  styleUrl: './causas-display.component.scss'
})
export class CausasDisplayComponent implements OnInit, OnChanges{

    @Input() causas: Causa[] = [];
    selectedDelitos: string[] = [];
    filteredCausas: Causa[] = [];
    delitos: string[] = [];

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {

        if(!this.causas) {
            return;
        }

        let delitos = {};
        this.causas.forEach((causa) => {
            delitos[causa.nombreDelito]=causa.nombreDelito;
        });
        this.delitos = Object.values(delitos);
        this.filteredCausas = this.causas;

        console.log('change');

    }

    filter(){
        if (this.selectedDelitos.length > 0) {
            this.filteredCausas = this.causas.filter(causa=>{
                return this.selectedDelitos.includes(causa.nombreDelito);
            });
        } else {
            this.filteredCausas = this.causas;
        }
    }
}
