<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Plan" icon="pi pi-plus" class="p-button mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Plan" icon="pi pi-trash" class="p-button mr-2" (click)="deleteSelectedPlans()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="plans" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedPlans" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Planes</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="typePlan">Tipo de Plan <p-sortIcon field="typePlan"></p-sortIcon></th>
                        <th pSortableColumn="numberOfSearches">Numero de Búsquedas <p-sortIcon field="numberOfSearches"></p-sortIcon></th>
                        <th pSortableColumn="startingDate">Fecha de Inicio <p-sortIcon field="startingDate"></p-sortIcon></th>
                        <th pSortableColumn="endingDate">Fecha de Finalización <p-sortIcon field="endingDate"></p-sortIcon></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-plan>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="plan"></p-tableCheckbox>
                        </td>
                        <td><span class="p-column-title">Nombre</span>
                            {{plan.type}}
                        </td>
                        <td><span class="p-column-title">RUC</span>
                            {{plan.quantity}}
                        </td>
                        <td><span class="p-column-title">Teléfono</span>
                            {{plan.start_date}}
                        </td>
                        <td><span class="p-column-title">Teléfono</span>
                            {{plan.end_date}}
                        </td>
                    </tr>
                </ng-template>

            </p-table>

        </div>


        <p-dialog [formGroup]="form" [(visible)]="planDialog" [style]="{width: '450px'}" header="Nuevo Plan" [modal]="true" class="p-fluid">
            <div class="field">
                <label>Tipo de Plan:</label>
                <p-dropdown [options]="options" formControlName="type" placeholder="Seleccione"></p-dropdown>
            </div>

            <div class="field">
                <label>Cantidad de Búsquedas:</label>
                <input type="number" pInputText formControlName="quantity" placeholder="Ingrese cantidad">
            </div>

            <div *ngIf="form.get('type').value === 'Clientes'">
                <div class="field">
                    <label>Fecha de Inicio:</label>
                    <input type="date" pInputText formControlName="start_date">
                </div>

                <div class="field">
                    <label>Fecha de Fin:</label>
                    <input type="date" pInputText formControlName="end_date">
                </div>
            </div>

            <p-footer>
                <button pButton type="button" label="Guardar" icon="pi pi-check" (click)="createPlan()"></button>
                <button pButton type="button" label="Cancelar" icon="pi pi-times" class="p-button-secondary" (click)="hideDialog()"></button>
            </p-footer>
        </p-dialog>

        <p-dialog [(visible)]="deletePlansDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el plan?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deletePlansDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>
