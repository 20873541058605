import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {RoleInterface} from "../../interfaces/role";
import {RolesService} from "../../services/roles.service";
import {DropdownModule} from "primeng/dropdown";
import {ListboxModule} from "primeng/listbox";
import {PermissionsService} from "../../services/permissions.service";
import {PermissionInterface} from "../../interfaces/permission";
import {PickListModule} from "primeng/picklist";


@Component({
    selector: 'app-roles',
    standalone: true,
    imports: [
        ButtonModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        NgIf,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToolbarModule,
        NgClass,
        ListboxModule,
        PickListModule
    ],
    templateUrl: './roles.component.html',
    styleUrl: './roles.component.scss',
    providers: [MessageService]
})
export class RolesComponent implements OnInit{
    roles: RoleInterface[];
    role: RoleInterface;
    selectedRoles: RoleInterface[] = [];

    permissions: PermissionInterface[];
    selectedPermissions: PermissionInterface[] = [];

    uuid: string;
    cols: any[] = [];
    form: FormGroup;

    deleteRolesDialog: boolean = false;
    submitted: boolean = false;
    roleDialog: boolean = false;
    editDialog: boolean = false;
    isSaving: boolean = false;

    constructor(private messageService: MessageService, private roleService: RolesService, private permissionService: PermissionsService) {}

    loadRoles(){
        this.roleService.getRoles().subscribe(data => {
            this.roles = data;
        });
    }

    loadPermissions() {
        this.permissionService.getPermissions().subscribe(permissions => {
            this.permissions = permissions;
            this.setAvailablePermissions();
        });
    }

    setAvailablePermissions(){
        const availablePermissions = this.permissions.filter(permission => {
            return this.form.get('permissions').value.includes(permission.uuid);
        });
        this.form.get('availablePermissions').setValue(availablePermissions);
    }

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl(''),
            uuid: new FormControl(null),
            permissions: new FormControl([]),
            availablePermissions: new FormControl([])
        });
        this.loadRoles();
        this.loadPermissions();
    }

    hideDialog() {
        this.roleDialog = false;
    }

    openNew() {
        this.form.reset({
            'name': "",
            'uuid': null
        });
        this.submitted = false;
        this.roleDialog = true;
    }

    createRole(){
        if (this.form.invalid){
            return;
        }

        this.roleService.createRole(this.form.value, this.selectedPermissions.map(permission=> {
            return permission.uuid;
        }))
            .subscribe(data => {
            this.hideDialog();
            this.loadRoles();
        });
    }

    performAction(){
        if (!this.form.get('uuid').value)
        {
            this.createRole();
        }
        else {
            this.updateRole();
        }
    }

    editSelectedRoles(role:RoleInterface) {
        this.form.reset({
            'name': role.name,
            'uuid': role.uuid
        });

        this.selectedPermissions = [];
        role.permissions.forEach(permissionUuid=>{
            this.permissions.forEach((permission, key)=> {
                if (permissionUuid == permission.uuid)
                {
                    this.selectedPermissions.push(this.permissions[key]);
                }
            });
        });
        this.role = role;
        this.submitted = false;
        this.roleDialog = true;
    }

    getFilteredSourceItems() {
        if (!this.permissions)
        {
            return [];
        }
        return this.permissions.filter(item => !this.selectedPermissions.includes(item));
    }

    updateRole(){
        if (this.isSaving) return;
        this.isSaving = true;

        this.roleService.updateRoles(this.form.value, this.selectedPermissions.map(permission=> {
            return permission.uuid;
        })).subscribe(data => {
            this.roleDialog = false;
            this.loadRoles();
            this.isSaving = false;
        });
    }

    deleteSelectedRoles() {
        this.deleteRolesDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteRolesDialog = false;
        this.selectedRoles.forEach((role) => {
            this.roleService.deleteRole(role.uuid.toString()).subscribe(()=> {
                this.loadRoles();
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Roles Eliminados', life: 3000 });
            });
        });
    }

}
