<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="records" rows="10" paginator="true">
        <ng-template pTemplate="header">
            <tr>
                <th class="min-w-21rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombres
                    </div>
                </th>
                <th class="min-w-12rem">
                    <div class="flex justify-content-between align-items-center">
                        Alias
                    </div>
                </th>
                <th class="min-w-12rem">
                    <div class="flex justify-content-between align-items-center">
                        GDO
                    </div>
                </th>
                <th class="min-w-12rem">
                    <div class="flex justify-content-between align-items-center">
                        Identificación
                    </div>
                </th>
                <th class="min-w-12rem">
                    <div class="flex justify-content-between align-items-center">
                        Localidad
                    </div>
                </th>
                <th class="min-w-12rem">
                    <div class="flex justify-content-between align-items-center">
                        Justificación
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    {{record.nombres}}
                </td>
                <td>
                    {{record.alias}}
                </td>
                <td>
                    {{record.gdo}}
                </td>
                <td>
                    ({{record.tipo_documento}}) {{record.identificacion}}
                </td>
                <td>
                    {{record.localidad}}
                </td>
                <td>
                    {{record.justificacion}}
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>






