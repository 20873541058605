<p-toast [life]="5500"></p-toast>
<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/logos/APLASoft.png" alt="AplaSoft" class="mb-3 w-14rem flex-shrink-0">
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">

            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="text-center mb-5">
                    <!--<div class="text-900 text-3xl font-medium mb-3">AplaSoft</div>-->
                    <span class="text-600 font-medium">Reestablecer contraseña</span>
                </div>

                <div [formGroup]="form">
                    <form>
                        <label for="password" class="block text-900 text-xl font-medium mb-2">Contraseña</label>
                        <p-password [disabled]="isLoading" formControlName="password" type="password" placeholder="Contraseña" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>

                        <label class="block text-900 font-medium text-xl mb-2">Repetir la contraseña</label>
                        <p-password  [disabled]="isLoading" formControlName="confirmPassword" type="password" placeholder="Repetir la contraseña" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>


                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <button [loading]="isLoading" pButton pRipple label="Cambiar contraseña" class="w-full p-3 text-xl" (click)="changePassword()"></button>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>
</div>
