import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class UrlService {
    constructor(private http: HttpClient) { }

    getInstanceLink = (uuid:string, url: string) => {
        return this.http.post<LinkResponse>('url/instances', {
            uuid: uuid,
            file: url
        });
    }

    getReportLink = (uuid:string, url: string) => {
        return this.http.post<LinkResponse>('url/report', {
            uuid: uuid,
            file: url
        });
    }
}

export interface LinkResponse {
    url: string;
}
