import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {CompanyInterface} from "../interfaces/company";

@Injectable({
  providedIn: 'root'
})

export class CompaniesService {

    constructor(private http: HttpClient) { }

    public getCompanies = (groupUuid:string): Observable<{companies: CompanyInterface[]}> => {
        const params = new HttpParams().set('uuid', groupUuid);
        return this.http.get<{companies: CompanyInterface[]}>('companies', {
            params
        });
    }

    public deleteCompanies = (id: string):Observable<any> => {
        return this.http.delete(`companies/${id}`);
    }

    public createCompanies = (company:CompanyInterface, groupUuid:string, file:File): Observable<CompanyInterface> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('groupUuid', groupUuid);
        formData.append('name', company.name);
        formData.append('nui', company.nui);
        formData.append('address', company.address);
        formData.append('phone', company.phone);
        formData.append('activity', company.activity);
        formData.append('legal_representative_id', company.legal_representative_id.toString());
        formData.append('legal_representative', company.legal_representative);
        formData.append('employee_numbers', company.employee_numbers.toString());
        formData.append('registration_number', company.registration_number);

        const headers = new HttpHeaders({});

        return this.http.post<CompanyInterface>('companies', formData, {
            headers: headers
        });

    }

    public updateCompanies = (company:CompanyInterface, groupUuid:string, file:File): Observable<CompanyInterface> => {
        console.log(company);

        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('file', file);
        formData.append('groupUuid', groupUuid);
        formData.append('name', company.name);
        formData.append('nui', company.nui);
        formData.append('address', company.address);
        formData.append('phone', company.phone);
        formData.append('activity', company.activity);
        formData.append('legal_representative_id', company.legal_representative_id.toString());
        formData.append('legal_representative', company.legal_representative);
        formData.append('employee_numbers', company.employee_numbers.toString());
        formData.append('registration_number', company.registration_number);

        const headers = new HttpHeaders({});

        return this.http.post<CompanyInterface>('companies/'+company.uuid, formData, {
            headers: headers
        });

    }

}
