import {Component, Input} from '@angular/core';
import {DividerModule} from "primeng/divider";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-fge-notices-display',
  standalone: true,
    imports: [
        DividerModule,
        SharedModule,
        TableModule,
        NgForOf
    ],
  templateUrl: './fge-notices-display.component.html',
  styleUrl: './fge-notices-display.component.scss'

})
export class FgeNoticesDisplayComponent {
    @Input() records: NoticeFGE[] = [];
    test =0;
}

export interface NoticeFGE {
    title: string;
    lugar: string;
    fecha: string;
    hora: string;
    digitador: string;
    estado: string;
    oficio: string;
    delito: string;
    unidad: string;
    fiscalia: string;
    subjects: SubjectFGE[];
}

export interface SubjectFGE {
    cedula: string;
    name: string;
    estado: string;
}
