<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="penas" rows="10" paginator="true">

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        ID Juicio
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre del Delito
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Fecha de Providencia
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-pena>
            <tr>
                <td>
                    {{pena.idJuicio}}
                </td>
                <td>
                    {{pena.nombreDelito}}
                </td>
                <td>
                    {{pena.fechaProvidencia}}
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
