import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {LoadSearchCountAction} from "./store/user/search-count/search-count.actions";
import {LoadCurrentUserAction} from "./store/user/store/user.actions";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig, private router: Router, private store: Store) { }

    ngOnInit() {
        this.primengConfig.ripple = true;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd){
                if(!event.url.includes('login')) {
                    this.store.dispatch(new LoadSearchCountAction());
                    this.store.dispatch(new LoadCurrentUserAction());
                }
            }
        });

    }
}
