<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Grupo" icon="pi pi-plus" class="p-button mr-2" (click)="openNew()"></button>
                        <button pButton pRipple label="Eliminar Grupo" icon="pi pi-trash" class="p-button" (click)="deleteSelectedGroups()"></button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="groups" [columns]="cols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [(selection)]="selectedGroups" selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Grupos</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="code">Registro <p-sortIcon field="registry"></p-sortIcon></th>
                        <th pSortableColumn="code">Acciones <p-sortIcon field="registry"></p-sortIcon></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-group>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="group"></p-tableCheckbox>
                        </td>
                        <td><span class="p-column-title">Nombre</span>
                            {{group.name}}
                        </td>
                        <td><span class="p-column-title">Registro</span>
                            {{group.registry}}
                        </td>
                        <td><span class="p-column-title">Acciones</span>
                            <p-button label="Compañías" [routerLink]="['/groups', group.uuid, 'companies']" icon="pi pi-pencil" styleClass="p-button-secondary"></p-button>
                        </td>

                    </tr>
                </ng-template>

            </p-table>

        </div>

        <p-dialog [(visible)]="groupDialog" [style]="{width: '450px'}" header="Nuevo Grupo" [modal]="true" class="p-fluid">

            <ng-template pTemplate="content">
                <div class="field">
                    <label for="name">Nombre</label>
                    <input type="text" pInputText id="name" [(ngModel)]="group!.name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !group!.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !group!.name">Nombre es requerido.</small>
                </div>
                <div class="field">
                    <label for="name">Registro</label>
                    <input type="text" pInputText id="registry" [(ngModel)]="group!.registry" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !group!.registry}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !group!.registry">Apellido es requerido.</small>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="createGroup()"></button>
            </ng-template>

        </p-dialog>

        <p-dialog [(visible)]="deleteGroupsDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el grupo?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteGroupsDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>
